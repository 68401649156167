.project-group-create-form-heading {
  margin: 0 0 $line-height-computed-sm 0;
  padding: 0;
  text-align: center;

  &:not(:first-child) {
    margin-top: $line-height-computed-sm;
  }
}
.project-group-create-form {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  &-input {
    background-color: #ffffff;
    border: 1px solid #BABECB;
    color: $body-color;
    flex: 1;
    font-size: $font-size;
    line-height: $line-height;
    padding: $line-height-computed-sm $grid-gutter-width-sm;
  }
}

.project-group-create-form + .project-group-create-form > .project-group-create-form-input {
  border-top: none;
}
