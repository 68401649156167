.task-list-item {
  background-color: #FFFFFF;
  border-bottom: 2px solid $body-bg;
  padding: $line-height-computed-sm ($grid-gutter-width) $line-height-computed-sm $grid-gutter-width-sm;
  position: relative;

  &.is-loading .task-list-item-loader {
    display: block;
  }

  &.is-select-mode {
    margin-left: $grid-gutter-width-md;
  }

  .sortable-item.is-dragging &,
  .sortable-item.is-dragging &-view {
    cursor: grabbing !important;
  }
}

  .task-list-item-loader {
    background-color: fade_out(#F9FAFB, 0.5);
    bottom: 0;
    cursor: wait;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }

  .task-list-item-action-override {
    background-color: transparent;
    border: none;
    bottom: 0;
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .task-list-item-select-button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    left: -$grid-gutter-width-md;
    margin: 0;
    padding: $line-height-computed-xs $grid-gutter-width-xs;
    position: absolute;
    top: $line-height-computed-sm;

    &.is-disabled,
    &.is-active.is-disabled {

    }

    &:hover &-icon,
    &:active &-icon,
    &.is-active &-icon {
      color: $btn-primary;
    }

    &-icon {
      color: #CCCCCC;
      display: block;
    }
  }
