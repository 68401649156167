.task-modal-dialog {
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.2);
}

.task-modal-dialog-container {
  position: relative;
}

.task-modal-dialog-loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

/* ------ */
/* Header */
/* ------ */

.task-modal-dialog-header {
  align-items: center;
  border-bottom: solid 1px #D7D7D7;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.task-modal-dialog-header-button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-right: solid 1px #D7D7D7;
  border-radius: 8px 0 0 0;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: $line-height-computed-md;
  overflow: hidden;
  margin: 0 ;
  padding: 5px $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color;

  &:hover, &:active {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.task-modal-dialog-header-button-icon {

}

.task-modal-dialog-header-title {
  color: #AAAAAA;
  display: block;
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

/* ---- */
/* Form */
/* ---- */

.task-modal-dialog-form {
  margin: 0;
  padding: $line-height-computed-xs 0;

  &.mod-border-bottom {
    border-bottom: solid 1px #D7D7D7;
    margin-bottom: $line-height-computed-xs;
  }
}

.task-modal-dialog-form-group {
  margin: 0 0 $line-height-computed-xs 0;
  padding: 0;

  &.mod-date-picker {
    margin-bottom: 0;
    min-height: 374px;
  }

  .ember-flatpickr-input,
  &.mod-date-picker .flatpickr-input {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    color: $body-color;
    font-size: $font-size;
    line-height: $line-height;
    margin: 0 $grid-gutter-width-xs;
    padding: 2px $grid-gutter-width-xs;
    text-align: left;
    width: calc(100% - #{$grid-gutter-width-sm});
  }
}

.task-modal-dialog-form-label {
  color: #AAAAAA;
  display: block;
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed;
  line-height: ($line-height-computed / 14px);
  margin: 0;
  overflow: hidden;
  padding: 0 $grid-gutter-width-sm;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-modal-dialog-form-input {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  color: $body-color;
  display: block;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0 $grid-gutter-width-xs;
  padding: 2px $grid-gutter-width-xs;
  text-align: left;
  transition: 200ms ease-in-out border-color;
  width: calc(100% - #{$grid-gutter-width-sm});

  &:focus, &:active {
    border-color: $body-color;
  }
}

.task-modal-dialog-form-color-input {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  display: inline-block;
  margin: 0 $grid-gutter-width-xs;
  padding: 1px;

  // Affiche l'icône du color-picker
  &:hover > .sp-replacer .sp-preview .sp-preview-inner:after {
    opacity: 1;
  }

  // Preview du color-picker
  & > .sp-replacer {
    background-color: transparent;
    border: none;
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
    position: relative;

    // Bloc de couleur
    .sp-preview {
      border: none;
      border-radius: 4px;
      height: 26px;
      margin: 0;
      padding: 0;
      width: $grid-gutter-width-md;

      .sp-preview-inner {
        border-radius: 4px;

        &:after {
          font-size: 20px;
          left: $grid-gutter-width-sm;
          opacity: 0;
          text-align: center;
          top: 2px;
          transition: 200ms ease-in-out opacity;
        }
      }
    }
  }
}

.task-modal-dialog-form-fake-input {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  color: $body-color;
  cursor: not-allowed;
  display: block;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0 $grid-gutter-width-xs;
  padding: 2px $grid-gutter-width-xs;
  text-align: left;
  width: calc(100% - #{$grid-gutter-width-sm});
}

.task-modal-dialog-form-fake-input-icon {
  display: block;
  float: left;
  margin-right: $grid-gutter-width-xs;

  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
  &.mod-red {
    color: $brand-color-red;
  }
}

.task-modal-dialog-form-select {
  .ember-power-select-trigger {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-size: $font-size;
    line-height: $line-height;
    margin: 0 $grid-gutter-width-xs;
    min-height: 30px;
    padding: 2px $grid-gutter-width-xs;
    text-align: left;
    transition: 200ms ease-in-out border-color;

    &:focus, &:active {
      border-color: $body-color;
    }
  }

  .ember-power-select-selected-item {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.task-modal-dialog-form-select-dropdown {
  border-color: #d7d7d7;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.25);

  &.ember-basic-dropdown-content--below {
    border-bottom-color: #d7d7d7;
  }
  &.ember-basic-dropdown-content--above {
    border-top-color: #d7d7d7;
  }

  .ember-power-select-options {
  }

  .ember-power-select-option {
    color: $body-color;
    padding: 3px $grid-gutter-width-sm;
    transition: 200ms ease-in-out background-color,
                200ms ease-in-out color;

    &[aria-current=true] {
      background-color: $body-bg;
      color: $body-color;
    }

    &[aria-selected=true] {
      background-color: $brand-color-cyan;
      color: #FFFFFF;
      font-weight: 700;

      &[aria-current=true] {
        background-color: lighten($brand-color-cyan, 5%);
      }
    }
  }
}

/* ------------- */
/* Action button */
/* ------------- */

.task-modal-dialog-action-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm $line-height-computed-sm;
  width: 100%;
}

.task-modal-dialog-action-list-item {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: ($line-height-computed / 12px);
  margin: 0 0 0 auto;
  padding: 0 $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color;

  &.mod-left {
    margin-left: 0;
    margin-right: auto;
  }

  &:not([disabled]):not(.is-loading):hover,
  &:not([disabled]):not(.is-loading):focus {
    background-color: lighten($brand-color-cyan, 5%);
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: #d7d7d7;
  }
}
