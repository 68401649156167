.directory-modal {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  position: relative;
  width: 400px;
}

.directory-modal-title {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;
}

.directory-modal-content-loader {
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  display: flex;
  flex-direction: row;
  left: 0;
  position: absolute;
  right: 0;
  top: $line-height-computed-md;
  z-index: 10;
}

.directory-modal-content-loader-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.directory-modal-content-loader-icon {
  margin-right: $grid-gutter-width-xs;
  animation: loader-spin 1.2s linear infinite;
}

/* --------------------- */
/* Directory (list item) */
/* --------------------- */

.directory-modal-directory-item {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: $border-radius;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: (20 / 14);
  overflow: hidden;
  margin: 0;
  padding: 4px $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out border-color;

  &.mod-full-width {
    width: 100%;
  }

  &.mod-static {
    cursor: default;
    font-style: italic;
    font-weight: 500;
  }

  &.is-active {
    border-color: $brand-color-cyan;
  }

  &[disabled] {
    cursor: default;
  }

  &:not([disabled]):not(.mod-static) {
    &:hover, &:active {
      background-color: rgba(81, 101, 132, 0.1);
    }
  }
}

.directory-modal-directory-item-icon {
  color: $brand-color-cyan;
  margin-right: $grid-gutter-width-xs;

  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }

  .directory-modal-directory-item[disabled] & {
    color: #AAAAAA;
  }
}

.directory-modal-directory-item-text {
  flex: 1 1 auto;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  .directory-modal-directory-item[disabled] & {
    color: #AAAAAA;
  }
}

.directory-modal-directory-item-ref {
  @include directory-reference;
}

.directory-modal-directory-item-hint {
  flex: 0 0 auto;
  font-size: 12px;
  font-weight: 400;
  line-height: (20 / 12);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .directory-modal-directory-item[disabled] & {
    color: #AAAAAA;
  }
}

.directory-modal-directory-item-chevron {
  margin-left: $grid-gutter-width-xs;

  .directory-modal-directory-item[disabled] & {
    color: #AAAAAA;
  }
}

/* ------------- */
/* Type selector */
/* ------------- */

.directory-modal-type-selector {
  display: block;
  list-style: none;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

/* ----------- */
/* Breadcrumbs */
/* ----------- */

.directory-modal-breadcrumbs {
  align-items: center;
  border-bottom: solid 1px #D7D7D7;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.directory-modal-breadcrumbs-action {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 40px;
  overflow: hidden;
  margin: 0 ;
  padding: 5px $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color;

  &:hover, &:active {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.directory-modal-breadcrumbs-action-icon {}

.directory-modal-breadcrumbs-item {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $body-color;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: (20 / 14);
  overflow: hidden;
  margin: 0;
  padding: 5px $grid-gutter-width-sm;
}

.directory-modal-breadcrumbs-item-icon {
  color: $brand-color-cyan;
  margin-right: $grid-gutter-width-xs;

  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
}

.directory-modal-breadcrumbs-item-text {
  flex: 1 1 auto;
  text-align: left;
}

/* ------ */
/* Search */
/* ------ */

.directory-modal-search-container {
  &.is-hidden {
    display: none;
  }
}

.directory-modal-search {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width;
  position: relative;
}

.directory-modal-search:after {
  background-color: #D7D7D7;
  content: "";
  display: block;
  height: 1px;
  margin: $line-height-computed-sm $grid-gutter-width-lg $line-height-computed-sm;
  position: relative;
}

.directory-modal-search-input {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0;
  padding: 4px 8px 4px ($grid-gutter-width + 8px);
  text-align: center;
  transition: 200ms ease-in-out border-color;

  &:focus, &:active {
    border-color: #516584;
  }
}

.directory-modal-search-icon {
  color: #D7D7D7;
  left: ($grid-gutter-width + 8px);
  position: absolute;
  top: ($line-height-computed-sm + 5px);
  z-index: 1;
}

.directory-modal-search-hint {
  color: #AAAAAA;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: ($line-height-computed / 12px);
  text-align: center;
}

.directory-modal-result {
  margin: 0;
  max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  padding: 0;

  &.is-hidden {
    display: none;
  }
}

.directory-modal-result-loader {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.directory-modal-result-loader-icon {
  margin-right: $grid-gutter-width-xs;
  animation: loader-spin 1.2s linear infinite;
}

.directory-modal-result-message {
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.directory-modal-result-message-action {
  background-color: $sidebar-background-color;
  border: none;
  border-radius: 8px;
  color: $body-color;
  cursor: pointer;
  font-family: inherit;
  font-size: $font-size;
  font-weight: inherit;
  line-height: $line-height;
  margin: $line-height-computed-xs 0 0 0;
  padding: 2px 8px;
  transition: 200ms ease-in-out background-color,
    200ms ease-in-out color;

  &:hover, &:focus {
    background-color: fade_out($brand-color-cyan, 0.25);
    color: #ffffff;
  }
}

.directory-modal-result-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.directory-modal-result-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.directory-modal-result-list-item-browse {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: $border-radius;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: (20 / 14);
  overflow: hidden;
  margin: 0 0 0 $grid-gutter-width-sm;
  padding: 5px $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color;

  &:hover, &:active {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.directory-modal-result-list-item-browse-icon {}

/* -------------------- */
/* Generic contentainer */
/* -------------------- */

.directory-modal-generic-container {
  margin: 0;
  padding: $line-height-computed $grid-gutter-width-sm;
}

/* ------ */
/* Footer */
/* ------ */

.directory-modal-footer {
  border-top: solid 1px #D7D7D7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.directory-modal-footer-selection {
  flex: 1 1 auto;
  margin: 0 $grid-gutter-width-sm 0 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.directory-modal-footer-action {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: inherit;
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  padding: 2px 8px;
  margin: 0 0.2em;
  text-decoration: none;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):hover, &:not([disabled]):focus {
    background-color: fade_out($brand-color-cyan, 0.25);
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: default;
    background-color: #EAEAEA;
    opacity: 0.6;
  }

  &.mod-secondary {
    background-color: transparent;
    color: $brand-color-cyan;

    &:not([disabled]):hover, &:not([disabled]):focus {
      color: #FFFFFF;
    }
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
