.task-list {
  margin: 0 0 $line-height-computed 0;
  padding: 0 $grid-gutter-width;
  position: relative;

  &.mod-hover {
    &:before {
      background-color: fade_out($link-color, .85);
      bottom: -$line-height-computed-sm;
      content: '';
      display: none;
      left: $grid-gutter-width-sm;
      position: absolute;
      right: $grid-gutter-width-sm;
      top: -$line-height-computed-sm;
      z-index: -1;
    }

    &:hover:before {
      display: block;
    }
  }

  &:not(:hover) .task-list-header-context-menu {
    display: none;
  }
}

.task-list-create-button {
  align-items: center;
  background-color: #F9FAFB;
  border: none;
  color: $sidebar-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  padding: ($line-height-computed-sm - 2px) $grid-gutter-width ($line-height-computed-sm - 2px) $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out box-shadow,
              200ms ease-in-out color;
  width: 100%;

  &.is-spaced {
    margin-left: $grid-gutter-width-md;
    width: calc(100% - #{$grid-gutter-width-md});
  }

  &.is-loading {
    opacity: 0.5;
  }

  &:hover, &:focus {
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px rgba(51, 51, 51, 0.09);
    color: $body-color;
  }
}

.task-list-create-button-icon {
  padding: 4px;
}

.task-list-create-button-text {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 400;
  line-height: $line-height;
  margin-left: $grid-gutter-width-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
