.flatpickr-wrapper {
  display: block;
  outline: none;
  position: static;
}

.flatpickr-calendar {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  width: auto;

  &.inline {
    max-height: none;
    top: auto;
  }
}

/* ---------- */
/* Months nav */
/* ---------- */

.flatpickr-months {
  align-items: center;
  border: none;
  border-bottom: solid 1px #D7D7D7;
  border-top: solid 1px #D7D7D7;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: $line-height-computed-xs 0 0 0;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  border: solid 1px #D7D7D7;
  border-bottom: none;
  border-top: none;
  color: $blue-grey;
  cursor: pointer;
  fill: $blue-grey;
  flex: 0 0 $grid-gutter-width;
  height: auto;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  position: inherit;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color,
              200ms ease-in-out fill;
  z-index: auto;

  &:hover, &:focus {
    background-color: rgba(81, 101, 132, 0.1);
    color: $body-color;
    fill: $body-color;
  }

  & > span {
    display: block;
  }
}

.flatpickr-months .flatpickr-prev-month {
  border-left-color: transparent;
}

.flatpickr-months .flatpickr-next-month {
  border-right-color: transparent;
}

/* --------------------- */
/* Current month & year  */
/* --------------------- */

.flatpickr-months .flatpickr-month {
  flex: 1 1 auto;
  line-height: $line-height;
  height: auto;
  overflow: visible;
  position: inherit;
}

.flatpickr-current-month {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: inherit;
  font-weight: inherit;
  position: static;
  padding: 0;
  transform: none;
  width: auto;

  & > .numInputWrapper {
    background-color: transparent;
    border: none;
    color: $blue-grey;
    display: inherit;
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    transition: 200ms ease-in-out background-color,
                200ms ease-in-out color;
    width: auto;

    &:hover, &:focus {
      background-color: rgba(81, 101, 132, 0.1);
      color: $body-color;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
    input::-ms-clear {
      display: none;
    }

    & > .numInput {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      background-color: transparent;
      border: none;
      color: $blue-grey;
      display: block;
      font-family: $font-family;
      font-size: $font-size;
      font-weight: 500;
      line-height: $line-height;
      margin: 0;
      width: 100px;
      padding: $line-height-computed-xs $grid-gutter-width-sm;
      transition: 200ms ease-in-out color;

      &:hover, &:focus {
        color: $body-color;
      }

      &.cur-year {

      }
    }

    & > span.arrowUp,
    & > span.arrowDown {
      background-color: transparent;
      border: solid 1px transparent;
      border-left-color: #D7D7D7;
      color: $blue-grey;
      cursor: pointer;
      display: block;
      height: 18px;
      margin: 0;
      padding: $line-height-computed-xs $grid-gutter-width-xs;
      position: absolute;
      right: 0;
      transition: 200ms ease-in-out background-color,
                  200ms ease-in-out color;
      width: $grid-gutter-width;

      &:hover, &:focus {
        background-color: rgba(81, 101, 132, 0.1);
        color: $body-color;
      }

      &:after {
        border-left: $grid-gutter-width-xs solid transparent;
        border-right: $grid-gutter-width-xs solid transparent;
        display: block;
        content: "";
        height: 0;
        transition: 200ms ease-in-out border-color;
        width: 0;
        z-index: 1;
      }
    }

    & > span.arrowUp {
      top: 0;

      &:after {
        border-bottom: $line-height-computed-xs solid $blue-grey;
      }

      &:hover, &:focus {
        &:after {
          border-bottom-color: $body-color;
        }
      }
    }

    & > span.arrowDown {
      border-top-color: #D7D7D7;
      bottom: 0;

      &:after {
        border-top: $line-height-computed-xs solid $blue-grey;
      }

      &:hover, &:focus {
        &:after {
          border-top-color: $body-color;
        }
      }
    }
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: transparent;
  border: none;
  border-right: solid 1px #D7D7D7;
  color: $blue-grey;
  cursor: pointer;
  flex: 1 0 125px;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 500;
  height: $line-height-computed + $line-height-computed-sm;
  line-height: $line-height;
  margin: 0;
  padding: ($line-height-computed-xs + 1px) $grid-gutter-width-sm;
  position: inherit;
  text-transform: uppercase;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;

  &:hover, &:focus {
    background-color: rgba(81, 101, 132, 0.1);
    color: $body-color;
  }
}

/* -------- */
/* Calendar */
/* -------- */

.flatpickr-innerContainer,
.flatpickr-rContainer {
  margin: 0;
  padding: 0;
}

/* -------------------- */
/* Calendar - Week days */
/* -------------------- */

.flatpickr-weekdays {
  display: block;
  height: auto;
  width: auto;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  border-bottom: solid 1px #D7D7D7;
  display: grid;
  flex: none;
  grid-template-columns: repeat(7, $grid-gutter-width-md);
}

span.flatpickr-weekday {
  color: #AAAAAA;
  display: inherit;
  flex: none;
  font-size: inherit;
  line-height: inherit;
  padding: $line-height-computed-sm 0;
  text-align: center;
  text-transform: uppercase;

  &:not(:last-child) {
    border-right: solid 1px #D7D7D7;
  }
}

/* --------------- */
/* Calendar - Days */
/* --------------- */

.flatpickr-days {
  display: block;
  margin-bottom: 0;
  overflow: visible;
  position: inherit;
  width: auto;

  & > .dayContainer {
    display: grid;
    grid-template: auto / repeat(7, $grid-gutter-width-md);
    min-width: 0;
    max-width: none;
    transform: none;
    width: auto;
  }
}

.flatpickr-day {
  border: none;
  border-bottom: solid 1px #D7D7D7;
  border-radius: 0;
  box-shadow: none;
  color: #AAAAAA;
  cursor: pointer;
  display: inherit;
  font-weight: 500;
  height: auto;
  line-height: inherit;
  max-width: none;
  padding: $line-height-computed-sm 0 ($line-height-computed-sm - 1px);
  position: relative;
  text-align: center;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;
  width: auto;

  .rangeMode & {
    margin-top: 0;
  }

  &:not(:nth-child(7n)) {
    border-right: solid 1px #D7D7D7;
  }

  &:hover, &:focus {
    background-color: rgba(81, 101, 132, 0.1);
    color: $body-color;
  }

  &.prevMonthDay,
  &.nextMonthDay {
    background-color: transparent;
    background-image: url("data:image/svg+xml,<svg width='48' height='48' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'><g fill='rgba(0, 0, 0, 0.03)' fill-opacity='1' fill-rule='evenodd'><path d='M0 48L48 0H24L0 24M48 48V24L24 48'/></g></svg>");
    border-color: #D7D7D7;
    color: #AAAAAA;
    font-weight: 400;

    &.inRange, &.startRange, &.endRange {
      background-image: url("data:image/svg+xml,<svg width='48' height='48' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'><g fill='rgba(0, 0, 0, 0.03)' fill-opacity='1' fill-rule='evenodd'><path d='M0 48L48 0H24L0 24M48 48V24L24 48'/></g></svg>");
      border-color: #D7D7D7;
    }

    &:hover, &:focus {
      background-color: rgba(81, 101, 132, 0.1);
      background-image: url("data:image/svg+xml,<svg width='48' height='48' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'><g fill='rgba(0, 0, 0, 0.03)' fill-opacity='1' fill-rule='evenodd'><path d='M0 48L48 0H24L0 24M48 48V24L24 48'/></g></svg>");
      border-color: #D7D7D7;
      color: $body-color;
    }
  }

  &.today {
    border-color: #D7D7D7;
    overflow: hidden;

    // Triangle dans le coin supérieur droit
    &:after {
      background-color: $brand-color-cyan;
      content: " ";
      height: $line-height-computed;
      position: absolute;
      right: (-$grid-gutter-width-sm - 2px);
      top: (-$line-height-computed-sm - 2px);
      transform: rotate(45deg);
      width: $grid-gutter-width;
    }

    &:hover, &:focus {
      background-color: rgba(81, 101, 132, 0.1);
      border-color: #D7D7D7;
      color: $body-color;
    }
  }

  // Le double ".startRange.startRange" n'est pas une erreur de copié-collé...
  &.startRange, &.startRange.startRange,
  &.endRange, &.endRange.endRange {
    background-color: $color-success;
    border-color: #D7D7D7;
    border-radius: 0;
    box-shadow: none !important;
    color: #FFFFFF;

    &:hover, &:focus {
      background-color: $color-success;
      color: #FFFFFF;
    }

    &.inRange {
      background-color: $color-success;
      color: #FFFFFF;
    }
  }

  &.inRange {
    background-color: transparentize($color-success, 0.75);
    border-color: #D7D7D7;
    box-shadow: none;
    color: desaturate($color-success, 40%);

    &:hover, &:focus {
      background-color: rgba(81, 101, 132, 0.1);
      border-color: #D7D7D7;
      color: $body-color;
    }

    &.prevMonthDay, &.nextMonthDay {
      background-color: transparentize($color-success, 0.75);

      &:hover, &:focus {
        background-color: rgba(81, 101, 132, 0.1);
      }
    }

    &.today {
      background-color: transparentize($color-success, 0.75);
      border-color: #D7D7D7;

      &:hover, &:focus {
        background-color: rgba(81, 101, 132, 0.1);
      }
    }
  }

  &.selected {
    background-color: $color-success;
    border-color: #D7D7D7;
    color: #FFFFFF;

    &.startRange, &.endRange,
    &.startRange.endRange {
      border-radius: 0;

      &:hover, &:focus {
        background-color: $color-success;
        border-color: #D7D7D7;
        color: #FFFFFF;
      }
    }

    &:hover, &:focus {
      background-color: $color-success;
      border-color: #D7D7D7;
      color: #FFFFFF;
    }
  }
}
