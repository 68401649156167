.main-loader {
  align-items: center;
  background-color: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  display: flex;
  margin: $line-height-computed-md auto;
  padding: $line-height-computed $grid-gutter-width;

  &-icon {
    margin-right: $grid-gutter-width-sm;
    animation: loader-spin 1.2s linear infinite;

    @keyframes loader-spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &-text {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
}
