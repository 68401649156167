.task-modal-milestone {
  width: 300px;
}

/* -------------- */
/* Milestone list */
/* -------------- */

.task-modal-milestone-list {
  border-bottom: solid 1px #D7D7D7;
  margin: 0 0 $line-height-computed-xs;
  max-height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $line-height-computed-xs 0;
}

.task-modal-milestone-list-item {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  text-align: left;
  transition: 200ms ease-in-out background-color;
  width: 100%;

  &.is-selected {
    font-weight: 700;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]):not(.is-loading):hover,
  &:not([disabled]):not(.is-loading):focus {
    background-color: $body-bg;
  }
}

.task-modal-milestone-list-item-text {
  flex: 1 1 auto;
  margin: 0 auto 0 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-modal-milestone-list-item-icon {
  flex: 0 0 auto;
  transition: 200ms ease-in-out opacity;

  &.mod-add {
    color: $color-success;
    opacity: 0;
  }

  .task-modal-milestone-list-item.is-selected & {
    color: $brand-color-cyan;
  }

  .task-modal-milestone-list-item:hover &.mod-add,
  .task-modal-milestone-list-item:focus &.mod-add {
    opacity: 1;
  }
}

.task-modal-milestone-list-empty {
  font-size: 12px;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  padding: $line-height-computed-xs 0;
  text-align: center;
}

.task-modal-milestone-list-empty-icon {
  display: block;
}
