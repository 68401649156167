.homepage-action-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0 $grid-gutter-width;
  justify-content: space-evenly;
}

.homepage-action-list-item {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #F2F2F2;
  border-radius: $border-radius;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width;
  text-decoration: none;
  transition:
    200ms ease-in-out background-color,
    200ms ease-in-out border-color;

  @media screen and (min-width: $screen-md) {
    min-width: 175px;
  }

  &:hover, &:focus {
    background-color: rgba(81, 101, 132, 0.1);
    border-color: rgba(81, 101, 132, 0.05);
  }
}

.homepage-action-list-item-icon {
  flex: 0 0 24px;
  margin: 0 0 $grid-gutter-width-sm 0;

  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
}

.homepage-action-list-item-text {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: (24 / 14);
}
