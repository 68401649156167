.project-member-list {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0 0 0 $grid-gutter-width-xs;

  &.mod-overflow {
    padding-left: 20px;
  }
}

.project-member-list-item {
  background-color: $sidebar-border-color;
  border: 2px solid #ffffff;
  border-radius: 50%;
  font-family: inherit;
  height: 32px;
  margin: 0 0 0 (-$grid-gutter-width-xs);
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 32px;

  .project-member-list.mod-overflow & {
    margin-left: -20px;
  }
}

.project-member-list-item-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: (28 / 12);
}
