.digit-code-container {
  display: grid;
  gap: $grid-gutter-width-xs;
  grid-template-columns: repeat(6, minmax(0, 1fr));

  .digit-code-input {
    height: 50px;
    text-align: center;
    font-size: $font-size;
  }
}
