.mini-sidebar {
  align-self: stretch;
  background-color: $sidebar-background-color;
  border-right: solid 1px $sidebar-border-color;
  border-top: solid 2px $body-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

/* ------------------ */
/* .mini-sidebar-item */
/* ------------------ */

.mini-sidebar-item {
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px $sidebar-border-color;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  height: 2 * $line-height-computed;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-decoration: none;

  &.mod-spaced {
    margin-top: auto;
  }

  &:hover .mini-sidebar-item-icon,
  &:active .mini-sidebar-item-icon {
    background-color: $sidebar-hover-color;
  }
  &.is-active .mini-sidebar-item-icon {
    background-color: #ffffff;
  }
}

.mini-sidebar-item-icon {
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  line-height: (32 / 24);
  position: relative;
  transition: 200ms ease-in-out background-color;
  width: 32px;
}

.mini-sidebar-item-icon-badge {
  background-color: $brand-color-cyan;
  border-radius: 50%;
  bottom: 0;
  display: block;
  height: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 10px;
  z-index: 1;
}

/* ------------------ */
/* .mini-sidebar-user */
/* ------------------ */

.mini-sidebar-user {
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px $sidebar-border-color;
  border-top: solid 1px $sidebar-border-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  height: 2 * $line-height-computed;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  padding: 0;

  &:hover .mini-sidebar-user-content,
  &:active .mini-sidebar-user-content {
    background-color: $sidebar-hover-color;
  }
  &.is-active .mini-sidebar-user-content {
    background-color: #ffffff;
  }
}

.mini-sidebar-user-content {
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  transition: 200ms ease-in-out background-color;
  width: 32px;
}

.mini-sidebar-user-initials,
.mini-sidebar-user-avatar {
  background-color: $body-color;
  border: solid 1px #D7D7D7;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed;
  line-height: (22 / 12);
  overflow: hidden;
  text-align: center;
  width: $grid-gutter-width;
}

/* -------------------- */
/* .mini-sidebar-search */
/* -------------------- */

.mini-sidebar-search {
  background-color: transparent;
  border: none;
  border-bottom: solid 1px $sidebar-border-color;
  margin: 0;
  padding: ($line-height-computed-xs + 2px) 8px ($line-height-computed-xs + 1px);
  position: relative;
  text-align: left;
}

.mini-sidebar-search-button {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  height: 32px;
  line-height: $line-height;
  margin: 0;
  padding: ($line-height-computed-xs / 2) $grid-gutter-width-xs;
  position: relative;
  text-align: left;
  transition: 200ms ease-in-out border-color;
  width: 32px;

  &:hover, &:focus {
    border-color: $body-color;
  }
}

.mini-sidebar-search-button-icon {
  color: #aaaaaa;
  font-size: 20px;
}
