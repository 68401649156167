.context-menu {
  min-width: 0;
  width: auto;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;

  &.mod-max-250 {
    max-width: 250px;
  }

  &.mod-max-350 {
    max-width: 350px;
  }

  &.mod-max-480 {
    max-width: 480px;
  }

  &.mod-max-80vw {
    max-width: 80vw;
  }
}

.context-menu-title {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;
  position: relative;
}

.context-menu-close {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: (24 / 12);
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  position: absolute;
  right: 0;
  top: 0;
  transition: 200ms ease-in-out background-color;
  z-index: 1;

  &:hover, &:focus, &:active {
    background-color: #ffffff;
  }
}

.context-menu-close-icon {
  color: $blue-grey;
  display: block;
  font-size: 32px;
  transition: 200ms ease-in-out color;

  .context-menu-close:hover &,
  .context-menu-close:focus & {
    color: $body-color;
  }
}

.context-menu-nav {
  display: block;
  padding: 8px 0;
}

.context-menu-nav-item {
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: (20 / 14);
  overflow: hidden;
  margin: 0;
  padding: 5px $grid-gutter-width-sm;
  width: 100%;

  &.is-disabled,
  &[disabled] {
    color: #AAAAAA;
    cursor: not-allowed;
  }
}

.context-menu-nav-item-content {
  align-items: center;
  background-color: transparent;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 5px $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color;

  // Effet hover uniquement si élément pas désactivé
  .context-menu-nav-item:not(.is-disabled):hover &,
  .context-menu-nav-item:not([disabled]):hover &,
  .context-menu-nav-item:not(.is-disabled):active &,
  .context-menu-nav-item:not([disabled]):active & {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.context-menu-nav-item-icon {
  color: $body-color;
  margin-right: $grid-gutter-width-xs;
  max-width: $grid-gutter-width;

  &.mod-cyan {
    color: $brand-color-cyan;
  }
  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-yellow {
    color: $brand-color-yellow;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
  &.mod-red {
    color: $brand-color-red;
  }

  // Couleur grise si élément désactivé
  .context-menu-nav-item.is-disabled &,
  .context-menu-nav-item[disabled] & {
    color: #D7D7D7;
  }
}

.context-menu-content {
  display: block;
  padding: $line-height-computed-xs $grid-gutter-width $line-height-computed-xs 16px;
  max-height: calc(80vh - #{$line-height-computed-md});
  overflow: auto;
}
