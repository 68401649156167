.directory-selector {
  flex: 1 1 auto;
}

.directory-selector-type-list,
.directory-selector-directory-list {
  .ember-basic-dropdown-trigger {
    border-color: #BABECB;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: $line-height-computed-sm $grid-gutter-width-sm;
  }

  .ember-power-select-selected-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }

  .ember-power-select-clear-btn {
    border-radius: 6px;
    height: 24px;
    text-align: center;
    width: 24px;

    &:hover, &:focus {
      background-color: rgba(81, 101, 132, 0.1);
    }
  }
}

.directory-selector-type-list-icon {
  margin-right: $grid-gutter-width / 3;

  &.mod-private {
    color: $brand-color-blue;
  }
  &.mod-team {
    color: $brand-color-green;
  }
  &.mod-workspace {
    color: $brand-color-orange;
  }
}

.directory-selector-type-list-dropdown {
  .ember-power-select-options {
    border-color: #BABECB;
    padding: $line-height-computed-xs $grid-gutter-width-xs;
  }

  .ember-power-select-option {
    align-items: center;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: $line-height-computed-xs $grid-gutter-width-xs;

    &[aria-current="true"] {
      background-color: rgba(81, 101, 132, 0.1);
      color: inherit;
    }
  }
}

.directory-selector-type-list + .directory-selector-directory-list {
  & .ember-basic-dropdown-trigger {
    border-top: none;
  }
}
