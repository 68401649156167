.workspace-browser {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  width: 400px;
}

.workspace-browser-title {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;
}

.workspace-browser-search {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width;
  position: relative;
}

.workspace-browser-search:after {
  background-color: #D7D7D7;
  content: "";
  display: block;
  height: 1px;
  margin: $line-height-computed-sm $grid-gutter-width-lg $line-height-computed-sm;
  position: relative;
}

.workspace-browser-search-input {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0;
  padding: 4px 8px 4px ($grid-gutter-width + 8px);
  text-align: center;
  transition: 200ms ease-in-out border-color;

  &:focus, &:active {
    border-color: #516584;
  }
}

.workspace-browser-search-icon {
  color: #D7D7D7;
  left: ($grid-gutter-width + 8px);
  position: absolute;
  top: ($line-height-computed-sm + 5px);
  z-index: 1;
}

.workspace-browser-search-hint {
  color: #AAAAAA;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: ($line-height-computed / 12px);
  text-align: center;
}

.workspace-browser-result {
  margin: 0;
  max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  padding: 0;
}

.workspace-browser-result-loader {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.workspace-browser-result-loader-icon {
  margin-right: $grid-gutter-width-xs;
  animation: loader-spin 1.2s linear infinite;
}

.workspace-browser-result-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm $line-height-computed-sm;
}

.workspace-browser-result-list-item {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: $border-radius;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: (20 / 14);
  overflow: hidden;
  margin: 0;
  padding: 5px $grid-gutter-width-sm;
  width: 100%;
  transition: 200ms ease-in-out background-color;

  &:hover, &:active {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.workspace-browser-result-list-item-icon {
  color: $brand-color-orange;
  margin-right: $grid-gutter-width-xs;
}

.workspace-browser-result-list-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.workspace-browser-result-list-item-ref {
  @include directory-reference;
}

.workspace-browser-result-message {
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
