.project-team-invite-modal {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  min-width: 0;
  width: 400px;
  max-height: 80vh;
}

.project-team-invite-modal-title {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;
}

.project-team-invite-modal-description {
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.project-team-invite-modal-list {
  margin: 0;
  padding: $line-height-computed-xs 0;
}

.project-team-invite-modal-member-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  max-height: calc(80vh - 200px);
}

.project-team-invite-modal-member-list-item {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 8px $grid-gutter-width-sm;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: $body-bg;
  }
}

.project-team-invite-modal-member-list-item-tick {
  cursor: default;
  color: $color-success;
  display: block;
  margin: 0 $grid-gutter-width-xs 0 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
}

.project-team-invite-modal-member-list-item-checkbox {
  align-items: center;
  background: none;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin: 0 $grid-gutter-width-xs 0 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  transition:
    background-color 200ms ease-in-out,
    color 200ms ease-in-out;

  &:hover, &:focus {
    background-color: #ffffff;
  }
}

.project-team-invite-modal-member-list-item-checkbox-icon {
  color: $body-color;
}

.project-team-invite-modal-member-list-item-initials {
  background-color: $sidebar-border-color;
  border: 2px solid #ffffff;
  border-radius: 50%;
  flex: 0 0 auto;
  font-family: inherit;
  height: 32px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 32px;
}

.project-team-invite-modal-member-list-item-initials-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: (28 / 12);
}

.project-team-invite-modal-member-list-item-name {
  flex: 1 1 auto;
  font-weight: 700;
}

.project-team-invite-modal-member-list-item-role {
  flex: 0 0 150px;
  font-weight: 400;
  margin: 0 0 0 $grid-gutter-width-xs;
}

.project-team-invite-modal-action-list {
  border-top: solid 1px #D7D7D7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.project-team-invite-modal-action-list-item {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  padding: 2px 8px;
  margin: 0;
  text-decoration: none;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):hover, &:not([disabled]):focus {
    background-color: fade_out($brand-color-cyan, 0.25);
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: default;
    background-color: #EAEAEA;
  }
}
