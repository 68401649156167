.task-modal-custom-fields-form {
  width: 300px;
}

/* ---------- */
/* Field list */
/* ---------- */

.task-modal-custom-fields-form-list {
  border-bottom: solid 1px #D7D7D7;
  margin: 0 0 $line-height-computed-xs;
  max-height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $line-height-computed-xs 0;
}

.task-modal-custom-fields-form-list-drag-handle {
  display: inline-block;
  cursor: grab;
  color: rgba(123, 143, 174, 0.3);
  margin-right: $grid-gutter-width-sm;

  .sortable-item.is-dragging & {
    cursor: grabbing;
  }
}

.task-modal-custom-fields-form-list-item {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  text-align: left;
  transition: 200ms ease-in-out background-color;
  width: 100%;

  &:hover,
  &:focus {
    background-color: $body-bg;
  }
}

.task-modal-custom-fields-form-list-item-type {
  color: $blue-grey;
  flex: 0 0 auto;
  margin-right: $grid-gutter-width-sm * 0.5;
  transition: 200ms ease-in-out color;

  .task-modal-custom-fields-form-list-item:hover &,
  .task-modal-custom-fields-form-list-item:focus & {
    color: $body-color;
  }
}

.task-modal-custom-fields-form-list-item-text {
  flex: 1 1 auto;
  margin: 0 auto 0 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-modal-custom-fields-form-list-item-icon {
  flex: 0 0 auto;
  opacity: 0;
  transition: 200ms ease-in-out opacity;

  .task-modal-custom-fields-form-list-item:hover &,
  .task-modal-custom-fields-form-list-item:focus & {
    opacity: 1;
  }
}

.task-modal-custom-fields-form-list-empty {
  font-size: 12px;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  padding: $line-height-computed-xs 0;
  text-align: center;
}

.task-modal-custom-fields-form-list-empty-icon {
  display: block;
}

/* --------------------- */
/* Field: list - Options */
/* --------------------- */

.task-modal-custom-fields-form-field-list-option {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 $line-height-computed-xs 0;
  padding: 0 $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color;

  &:hover, &.is-focused {
    background-color: $body-bg;

    // Affiche l'icône du color-picker
    & > .sp-replacer .sp-preview .sp-preview-inner:after {
      opacity: 1;
    }
  }

  // Preview du color-picker
  & > .sp-replacer {
    background-color: transparent;
    border: none;
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
    position: relative;

    // Bloc de couleur
    .sp-preview {
      border: none;
      border-radius: $border-radius;
      height: $line-height-computed;
      margin: 0;
      padding: 0;
      width: $grid-gutter-width;

      .sp-preview-inner {
        border-radius: $border-radius;

        &:after {
          font-size: 20px;
          left: 1px;
          opacity: 0;
          text-align: center;
          top: 1px;
          transition: 200ms ease-in-out opacity;
        }
      }
    }
  }
}

.task-modal-custom-fields-form-field-list-option-input {
  border: 1px solid transparent;
  border-radius: 4px;
  color: $body-color;
  flex: 1 1 auto;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0 $grid-gutter-width-xs;
  min-width: 0;
  padding: 2px $grid-gutter-width-xs;
  text-align: left;
  transition: 200ms ease-in-out border-color;

  &:hover,
  .task-modal-custom-fields-form-field-list-option:hover &,
  .task-modal-custom-fields-form-field-list-option.is-focused & {
    border-color: #d7d7d7;
  }

  &:focus {
    border-color: $body-color;
  }
}

.task-modal-custom-fields-form-field-list-option-action {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: $blue-grey;
  cursor: pointer;
  flex: 0 0 auto;;
  height: $line-height-computed;
  line-height: $line-height;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  transition: 200ms ease-in-out opacity,
              200ms ease-in-out background-color,
              200ms ease-in-out color;
  width: $grid-gutter-width;

  &:not(:last-of-type) {
    margin-right: $grid-gutter-width-xs;
  }

  .task-modal-custom-fields-form-field-list-option:hover &,
  .task-modal-custom-fields-form-field-list-option.is-focused &,
  &:hover, &:focus {
    opacity: 1;
  }

  &:hover, &:focus {
    background-color: #FFFFFF;
    color: $body-color;
  }
}

.task-modal-custom-fields-form-field-list-option-action-icon {
  font-size: 20px;
  line-height: (24 / 20);
}
