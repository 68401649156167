.task-list-item-edit {
  display: flex;
  flex-direction: row;
}

  .task-list-item-edit-form {
    display: flex;
    flex-direction: row;
    flex: 1;

    &-input-container {
      align-items: center;
      color: $body-color;
      display: flex;
      flex: 1;
      flex-direction: row;
      padding: 0;
    }

    &-input-icon {
      color: $light-grey;
      font-size: 2em;
      // height: 32px;
      line-height: $line-height-computed / 36px;
      margin: 0 $grid-gutter-width-xs 0 0;
      width: 32px + $grid-gutter-width-xs;
    }
  }

    .task-list-item-edit-form-input {
      position: relative;
      background-color: #F9FAFB;
      border: none;
      color: $body-color;
      font-family: $font-family;
      font-size: $font-size;
      // Marge de gauche = padding-left d'un list-item + padding-right d'un list item + largeur de la checkbox
      padding: ((36px - $line-height-computed) / 2) $grid-gutter-width-xs;
      width: 100%;
      line-height: $line-height;

      &:focus {
        outline: none;
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #CDD0DD;
        font-style: italic;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #CDD0DD;
        font-style: italic;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #CDD0DD;
        font-style: italic;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #CDD0DD;
        font-style: italic;
      }
    }

    .task-list-item-edit-form-information {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 0 $grid-gutter-width;

      &-assigee {
        .ember-power-select-trigger {
          min-width: 200px;
        }
      }
    }

    .task-list-item-edit-form-action-list {
      display: flex;

      & > *:not(:last-child) {
        margin-right: $grid-gutter-width / 4;
      }
    }

