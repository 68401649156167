@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
    opacity: 1;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
    opacity: 1;
  }
  &::placeholder { /* May become standard */
    color: $color;
    opacity: 1;
  }
}

@mixin global-placeholder-color($color) {
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    opacity: 1;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    opacity: 1;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $color;
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $color;
    opacity: 1;
  }
  ::placeholder { /* May become standard */
    color: $color;
    opacity: 1;
  }
}