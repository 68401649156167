.avatar-list {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;

  &.mod-align-right {
    justify-content: flex-start;
  }

  &-item {
    &:not(:last-child) {
      margin-left: -$grid-gutter-width-xs;
    }

    .avatar-list.mod-align-right & {
      margin-left: 0;

      &:not(:first-child) {
        margin-right: -$grid-gutter-width-xs;
      }
    }
  }
}
