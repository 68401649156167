.new-milestone-form {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: $line-height-computed-md 0;
  padding: 0 $grid-gutter-width;
  position: relative;

  &.mod-mg-light {
    margin: $line-height-computed 0;
  }
}

.new-milestone-form-separator {
  background-color: rgba(123, 143, 174, 0.3);
  content: "";
  display: block;
  height: 1px;
  width: 30%;
}

.new-milestone-form-button {
  align-items: center;
  background-color: #F9FAFB;
  border: none;
  border-radius: $border-radius;
  color: $sidebar-color;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0 $grid-gutter-width;
  padding: $line-height-computed-xs $grid-gutter-width;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out box-shadow,
              200ms ease-in-out color;

  &:hover, &:focus {
    background-color: $brand-color-cyan;
    box-shadow: 0 2px 5px rgba(51, 51, 51, 0.09);
    color: #FFFFFF;
  }
}

.new-milestone-form-button-icon {
  margin-right: $grid-gutter-width-xs;
}

.new-milestone-form-button-text {
  white-space: nowrap;
}

.new-milestone-form-input {
  align-items: center;
  background-color: #FFFFFF;
  color: $body-color;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.new-milestone-form-input-icon {
  margin-right: $grid-gutter-width-sm;

  &:not(.mod-spinner) {
    padding: 4px;
  }
}

.new-milestone-form-input-field {
  position: relative;
  background-color: transparent;
  border: none;
  color: $body-color;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  width: 100%;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: #CDD0DD;
    font-style: italic;
  }
}
