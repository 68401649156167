.project-list-item {
  background: transparent;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  min-width: 0;
  text-align: inherit;
  text-decoration: none;

  &:hover:not(.is-loading) &-header-title, &:focus:not(.is-loading) &-header-title,
  &:hover:not(.is-loading) &-button-text, &:focus:not(.is-loading) &-button-text {
    text-decoration: underline;
  }
  &:hover:not(.is-loading) &-container,
  &:focus:not(.is-loading) &-container {
    box-shadow: 0px 5px 10px 0px rgba(163, 177, 199, 0.4);
  }
  &:hover:not(.is-loading) &-menu-action,
  &:focus:not(.is-loading) &-menu-action {
    display: block;
  }

  &.mod-button {
    align-items: stretch;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
    &.mod-button &-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

  &.mod-clickable {
    cursor: pointer;
  }

  &.mod-no-action {
    cursor: default;
  }
  &.mod-no-action:hover &-header-title, &.mod-no-action:focus &-header-title,
  &.mod-no-action:hover &-button-text, &.mod-no-action:focus &-button-text {
    text-decoration: none;
  }
  &.mod-no-action:hover &-container,
  &.mod-no-action:focus &-container {
    box-shadow: rgba(163, 177, 199, 0.3) 1px 1px 2px 0px;
  }

  &.is-loading {
    cursor: wait;
  }

  &-menu-action {
    margin-left: auto;
    border: none;
    background: none;
    padding: 0 0 0 $grid-gutter-width-sm;
    display: none;
    cursor: pointer;
    &-svg {
      &:hover, &:focus {
        fill: $color-info !important;
      }
      height: 18px !important;
      fill: $body-color !important;
    }
  }

  &-container {
    background: #ffffff;
    border-top: ($line-height-computed / 2) solid #7b8fae;
    border-radius: $border-radius;
    box-shadow: rgba(163, 177, 199, 0.3) 1px 1px 2px 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 6.5 * $line-height-computed;
    padding: $line-height-computed / 2;
    position: relative;

    .project-list-body.mod-gray & {
      border-top-color: #aaaaaa !important;
      background-color: #F9FAFB;
    }
  }

  &-header {
    display: flex;
    &-title {
      color: $body-color;
      line-height: $line-height-computed;
      margin: 0;
      min-width: 0;
      padding: 0;


      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .project-list-body.mod-gray & {
        color: #aaaaaa;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: row;

    &.mod-padding-top {
      padding-top: $line-height-computed;
    }

    &.mod-centered {
      align-items: center;
      flex: 1 1 auto;
      justify-content: center;
    }

    &-group {
      flex: 1 1 50%;

      &-heading {
        color: rgb(203, 210, 222);

        &.mod-align-right {
          text-align: right;
        }
      }
    }

    &-button {
      align-items: center;
      background-color: $brand-color-cyan;
      border: none;
      border-radius: $border-radius;
      display: flex;
      flex: 0 0 auto;
      color: #ffffff;
      cursor: pointer;
      font-family: inherit;
      font-size: 14px;
      font-weight: 700;
      line-height: (24 / 14);
      margin: 0;
      padding: 2px 8px;
      text-align: center;
      transition: 200ms ease-in-out background-color;

      &.mod-pending {
        background-color: #DEDEDE;
        color: $body-color;
        cursor: default;
        font-weight: 400;
      }

      .project-list-item:hover &:not(.mod-pending),
      .project-list-item:focus &:not(.mod-pending) {
        background-color: lighten($brand-color-cyan, 5%);
      }

      &-icon {
        margin-right: $grid-gutter-width-xs;
      }
    }
  }

  &-progress {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-top: $line-height-computed;

    &-bar {
      flex: 1;
      height: $line-height-computed / 1.8;
      position: relative;

      &-bg {
        background-color: $grey;
        border: 2px solid $body-bg;
        border-radius: $border-radius;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }

      &-inner {
        background-color: #7b8fae;
        border-radius: $border-radius * 1.5;
        height: 100%;
        min-width: 14px;
        position: relative;
        z-index: 2;

        &.mod-hidden {
          display: none;
        }
      }
    }

    &-text {
      flex: 0 0 1;
      margin-left: $grid-gutter-width-sm;
    }
  }
}

  .project-list-item-loader {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    bottom: 0;
    left: 0;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .project-list-item-position {
    align-self: center;
  }

  .project-list-item-button {
    align-items: center;
    color: $body-color;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    line-height: 36px;
    padding: 0;
    text-decoration: none;

    &-symbol {
      display: block;
      font-size: 4em;
    }

    &-text {
      display: block;
      font-size: 1.1em;
    }
  }
