.directory-section-header {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  margin: 0 0 $line-height-computed 0;
  padding: $line-height-computed $grid-gutter-width;
}

.directory-section-header-icon {
  font-size: 48px;
  margin-right: $grid-gutter-width-sm;

  &.mod-cyan {
    color: $brand-color-cyan;
  }
  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
}

.directory-section-header-title {
  color: $body-color;
  font-size: 28px;
  font-weight: 700;
  line-height: (32 / 28);
  margin: 0;
  padding: 0;
}

.directory-section-header-action {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: $border-radius;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: (24 / 14);
  margin: 0 0 0 auto;
  padding: $line-height-computed-xs $grid-gutter-width;
  text-align: inherit;
  transition: 200ms ease-in-out background-color;

  &:hover, &:active {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.directory-section-header-action-icon {
  margin: 0 $grid-gutter-width-xs 0 0;
}
