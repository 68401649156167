.flash-message-list {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 400px;
  padding: 0;
  position: fixed;
  right: $grid-gutter-width;
  top: $line-height-computed * 3;
  z-index: $flash-message-list-zindex;
}

  .flash-message {
    background-color: $grey;
    border-radius: $border-radius-sm;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    opacity: 0;
    padding: $line-height-computed-xs ($grid-gutter-width + $grid-gutter-width-sm) $line-height-computed-xs $grid-gutter-width-sm;
    position: relative;
    transition: opacity 500ms ease-in-out;

    &:not(:last-child) {
      margin-bottom: $line-height-computed-xs;
    }

    &.alert-success {
      background-color: $color-success;
    }

    &.alert-warning {
      background-color: $color-warning;
    }

    &.alert-info {
      background-color: $color-info;
    }

    &.alert-alert {
      background-color: $color-alert;
    }

    &.alert-secondary {
      background-color: $sidebar-color;
    }

    &.active {
      opacity: 1;

      &.exiting {
        opacity: 0;
      }
    }

    &-close {
      background-color: transparent;
      border: none;
      color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
      display: block;
      margin: 0;
      padding: $line-height-computed-xs $grid-gutter-width-xs;
      position: absolute;
      right: 0;
      top: 0;

      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";

      &:before {
        content: "close";
      }

      &:hover, &:focus {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    &-progress {
      border-radius: 0 0 $border-radius-sm $border-radius-sm;
      bottom: 0;
      height: $line-height-computed-xs / 2;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      z-index: 2;
    }

    &-progress-bar {
      background: rgba(255, 255, 255, 0.6);
      height: 100%;
      transition-property: width;
      transition-timing-function: linear;
      width: 100%;
    }

    &.active &-progress-bar {
      width: 0%;
    }
  }
