.activity-feed-modal {
  overflow: hidden;
  width: 400px;
}

.activity-feed-modal-header {
  align-items: center;
  border-bottom: solid 1px #D7D7D7;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.activity-feed-modal-header-title {
  color: #AAAAAA;
  display: block;
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.activity-feed-modal-header-button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-left: solid 1px #D7D7D7;
  border-radius: 0 8px 0 0;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: $line-height-computed-md;
  overflow: hidden;
  margin: 0 0 0 auto;
  padding: 5px $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color;

  &:hover, &:active {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.activity-feed-modal-header-button-icon {

}

/* ---- */
/* List */
/* ---- */

.activity-feed {
  background-color: #FFFFFF;
  max-height: 70vh;
  min-width: 200px;
  overflow-y: auto;
  position: relative;
}

/* ------ */
/* Loader */
/* ------ */

.activity-feed-loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0 0 8px 8px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: $line-height-computed-md + 1px;
  z-index: 10;
}

/* ----- */
/* Alert */
/* ----- */

.activity-feed-alert {
  align-items: center;
  background-color: lighten($color-warning, 38%);
  border: 1px solid desaturate($color-warning, 33%);
  border-radius: $border-radius;
  color: $body-color;
  display: flex;
  margin: $line-height-computed-sm $grid-gutter-width-sm;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
}

.activity-feed-alert-icon {
  margin-right: $grid-gutter-width-sm;
}

/* ------------------ */
/* List action button */
/* ------------------ */

.activity-feed-action-button {
  align-items: center;
  background-color: $brand-color-cyan;
  border: none;
  border-radius: $border-radius;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: ($line-height-computed / 14px);
  margin: $line-height-computed-sm auto;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  text-align: center;
  transition: 200ms ease-in-out background-color;

  &:hover, &:focus {
    background-color: lighten($brand-color-cyan, 5%);
  }
}

.activity-feed-action-button-icon {
  margin-right: $grid-gutter-width-sm;
}

/* --------- */
/* List item */
/* --------- */

.activity-feed-empty {
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.activity-feed-empty-icon {
  display: block;
  font-size: $line-height-computed-md;
  margin-bottom: $line-height-computed-sm;
}

/* --------- */
/* List item */
/* --------- */

.activity-feed-item {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-xs;
  position: relative;
  transition: 200ms ease-in-out background-color;

  &.is-new {
    background-color: rgba(0, 174, 204, 0.1);
  }

  &:hover, &:focus {
    background-color: #F9FAFB;
  }
}

.activity-feed-item-container {
  flex: 1 1 auto;
  margin: 0 $grid-gutter-width-xs;
  overflow: hidden;
  padding: 0;
}

.activity-feed-item-flag {
  background-color: transparent;
  border: 6px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  flex: 0 0 $grid-gutter-width;
  margin: 0;
  overflow: hidden;
  padding: 0;
  transition: 200ms ease-in-out border-color;

  .activity-feed-item.is-new & {
    &:hover, &:focus {
      border-color: $light-grey;
    }
  }
}

.activity-feed-item-flag-inner {
  background-color: transparent;
  border: 1px solid $light-grey;
  border-radius: 50%;
  display: block;
  height: $line-height-computed-sm;
  width: $grid-gutter-width-sm;

  .activity-feed-item.is-new & {
    background-color: $brand-color-cyan;
    border-color: $brand-color-cyan;
  }
}

.activity-feed-item-content {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
}

.activity-feed-item-author-initials {
  background-color: $sidebar-border-color;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: block;
  float: left;
  font-family: inherit;
  height: 24px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 24px;
}

.activity-feed-item-author-initials-text {
  border-radius: 50%;
  bottom: 0;
  color: #ffffff;
  display: block;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  line-height: (22 / 12);
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  z-index: 1;
}

.activity-feed-item-icon {
  color: lighten($link-color, 5%);
  flex: 0 0 1;
  margin-right: $grid-gutter-width-xs;

  .activity-feed-item.is-new & {
    color: lighten($sidebar-color, 15%);
  }
}

/* ---------------- */
/* List item - Meta */
/* ---------------- */

.activity-feed-item-meta {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  overflow: hidden;
  padding: 0 0 0 ($grid-gutter-width-xs / 2);
}

.activity-feed-item-meta-source {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
}

.activity-feed-item-meta-source-icon {
  font-size: 16px;
  margin-right: $grid-gutter-width-xs;
}

.activity-feed-item-meta-source-text {
  font-size: 12px;
  line-height: (20 / 12);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activity-feed-item-meta-date {
  color: $dark-grey;
  flex: 0 0 auto;
  font-size: 11px;
  font-style: italic;
  margin: 0 0 0 $grid-gutter-width-sm;
  overflow: hidden;
  padding: 0;
  line-height: ($line-height-computed / 11px);
  text-overflow: ellipsis;
  white-space: nowrap;
}
