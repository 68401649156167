.directory-section-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: $line-height-computed 0 18px 0;
  padding: 0 $grid-gutter-width;

  &:not(:first-of-type) {
    margin-top: 32px;
  }
}

.directory-section-title-text {
  color: $body-color;
  border: none;
  border-bottom: 2px solid #AAAAAA;
  font-family: inherit;
  font-size: 20px;
  font-weight: 700;
  line-height: (40 / 20);
  margin: 0;
  padding: 0 $grid-gutter-width-sm;
}

.directory-section-title-actions {
  margin-left: $grid-gutter-width;
}

.directory-section-title-action-item {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: $border-radius;
  display: inline-block;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: (24 / 14);
  margin: 0;
  padding: 2px 8px;
  text-align: center;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: lighten($brand-color-cyan, 5%);
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: not-allowed;
    background-color: #EAEAEA;
  }

  &:not(:first-child) {
    margin-left: $grid-gutter-width-sm;
  }
}
