.task-modal-field-list {
  margin: 0;
  padding: 0 $grid-gutter-width;
  width: 100%;
  display: grid;
  grid-row-gap: 0;
  grid-column-gap: $grid-gutter-width;

  @media (min-width: $screen-xss) {
    grid-template-columns: fit-content(30%) 1fr;
  }
  @media (min-width: $screen-sm) {
    grid-template-columns: fit-content(35%) 1fr;
  }
  @media (min-width: $screen-md) {
    grid-template-columns: fit-content(40%) 1fr;
  }
  @media (min-width: $screen-lg) {
    grid-template-columns: fit-content(50%) 1fr;
  }

  &.mod-empty {
    color: $blue-grey;
    padding-left: ($grid-gutter-width + $grid-gutter-width-sm);
    padding-right: ($grid-gutter-width + $grid-gutter-width-sm);
  }
}

/* --------- */
/* List item */
/* --------- */

.task-modal-field-list-item-title {
  color: $blue-grey;
  font-size: 14px;
  font-weight: 400;
  line-height: (24 / 14);
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-modal-field-list-item-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  overflow: hidden;
}

.task-modal-field-list-item-empty {
  color: $blue-grey;
  font-size: 14px;
  font-weight: 400;
  line-height: (24 / 14);
  padding: 0 $grid-gutter-width-xs;
}

/* ---------- */
/* Item value */
/* ---------- */

.task-modal-field-list-item-value {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: $blue-grey;
  cursor: pointer;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: inherit;
  line-height: $line-height;
  margin: 0;
  min-width: $grid-gutter-width-md;
  overflow: hidden;
  padding: 0 $grid-gutter-width-xs;
  text-overflow: ellipsis;
  text-align: left;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;
  white-space: nowrap;

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: rgba(0, 135, 255, 0.05) !important;
    color: $body-color;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.is-static {
    cursor: inherit;
  }

  & > a {
    color: $blue-grey;
    text-decoration: underline;
    transition: 200ms ease-in-out color;

    &:hover, &:focus, &:active {
      color: $body-color;
    }
  }
}

.task-modal-field-list-item-value[data-type="text"],
.task-modal-field-list-item-value[data-type="date"] {
  font-weight: 700;
}

.task-modal-field-list-item-value[data-type="text"] {
  padding-right: $grid-gutter-width;
}

.task-modal-field-list-item-value[data-type="list"] {
  color: #ffffff;
  font-size: 12px;
  line-height: (24px / 12px);
  text-align: center;
  text-transform: uppercase;

  &.mod-empty {
    color: $blue-grey;
    text-align: left;
  }
}

/* ---------- */
/* Item input */
/* ---------- */

.task-modal-field-list-item-input.mod-text {
  background-color: rgba(0, 135, 255, 0.05);
  border: none;
  border-radius: 4px;
  color: $body-color;
  display: block;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  margin: 0;
  padding: 0 $grid-gutter-width-xs;
  text-align: left;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;
  width: 100%;

  &.is-hidden {
    display: none;
  }

  &[disabled],
  &.is-loading {
    background-color: rgba(81, 101, 132, 0.03);
    color: rgba(81, 101, 132, 0.5);
    cursor: not-allowed;
  }

  &.is-loading {
    cursor: wait;
  }
}
