.import-modal-form-file-input {
  background-color: $light-grey;
  color: $body-color;
  cursor: pointer;
  display: block;
  margin: auto 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
  width: 100%;
}

.import-modal-form-file-input-link {
  text-decoration: underline;
}
