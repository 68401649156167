.reassign-tasks-modal {
  max-width: 50%;

  &-list {
    list-style: none;
    margin: $line-height-computed-sm 0 0 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;

    &-item {
      border-left: solid 4px $light-grey;
      display: block;
      padding: $line-height-computed-xs $grid-gutter-width-sm $line-height-computed-xs ($grid-gutter-width-sm - 4px);

      &:not(:last-child) {
        margin-bottom: $line-height-computed-sm;
      }

      &-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &-details {
          color: $sidebar-color;
          font-style: italic;
        }
      }
    }
  }
}
