.directory-page-header {
  align-items: center;
  background-color: #ffffff;
  border: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 $line-height-computed 0;
  padding: 0;
}

.directory-page-header-meta {
  align-items: center;
  border-top: 2px solid $body-color;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 0 0 $grid-gutter-width-sm;

  &.mod-blue {
    border-top-color: $brand-color-blue;
  }
  &.mod-green {
    border-top-color: $brand-color-green;
  }
  &.mod-orange {
    border-top-color: $brand-color-orange;
  }
  &.mod-red {
    border-top-color: $brand-color-red;
  }
}
