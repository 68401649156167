$offset: 200;
$duration: 1.5s;

.spinner-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $grid-gutter-width-xs;
}

.spinner {
  animation: rotator $duration linear infinite;
  stroke: $link-color;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite,
}


@keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
    stroke-dashoffset: $offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform:rotate(450deg);
  }
}
