.task-list-header {
  align-items: center;
  background-color: $body-bg;
  display: flex;
  flex-direction: row;
  margin: 0;
  min-width: 0;
  padding: $line-height-computed-sm $grid-gutter-width $line-height-computed-sm $grid-gutter-width-sm;
  position: relative;

  &.is-select-mode {
    margin-left: $grid-gutter-width-md;
  }

  .sortable-item.is-dragging & {
    cursor: grabbing;
  }
}

  .task-list-header-icon {
    background-color: transparent;
    border: none;
    color: rgba(123, 143, 174, 0.3);
    cursor: default;
    margin: -2px $grid-gutter-width-xs 0 0;
    padding: 0;

    &.mod-drag-handle {
      cursor: grab;

      .sortable-item.is-dragging & {
        cursor: grabbing;
      }
    }

    &.is-clickable {
      cursor: pointer;
    }
  }

  .task-list-header-title {
    background: transparent;
    border: none;
    border-bottom: 1px dashed transparent;
    color: $body-color;
    cursor: text;
    flex-grow: 1;
    font-family: inherit;
    font-size: 15px;
    font-weight: 500;
    line-height: ($line-height-computed / 15px);
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    transition: 200ms ease-in-out background-color,
                200ms ease-in-out border-color;
    vertical-align: middle;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.mod-editable:hover {
      background-color: #F9FAFB;
      border-color: $sidebar-color;
    }

    &.mod-assignee {
      padding-left: $grid-gutter-width + $grid-gutter-width-sm;
    }
    &.mod-assignee.mod-caret {
      padding-left: $grid-gutter-width * 2;

      .task-list-header-title-assignee-initials {
        left: $grid-gutter-width-sm + 6;
      }
    }
  }
    .task-list-header-title-assignee-initials {
      left: 0;
      position: absolute !important;
    }

  .task-list-header-title-input-container {
    flex-grow: 1;
    margin: (-$line-height-computed-xs) 0 (-$line-height-computed-xs) (-$grid-gutter-width-xs);
  }
    .task-list-header-title-input {
      background-color: #F9FAFB;
      border: dashed 1px rgba(123, 143, 174, 0.3);
      color: $body-color;
      display: block;
      font-family: inherit;
      font-size: 15px;
      font-weight: 500;
      line-height: ($line-height-computed / 15px);
      margin: 0;
      overflow: hidden;
      padding: ($line-height-computed-xs - 1) ($grid-gutter-width-xs - 1);
      position: relative;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: 100%;
      white-space: nowrap;
    }

  .task-list-header-context-menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: $font-size;
      font-family: $font-family;
      line-height: $line-height;
      margin: 0 (-$grid-gutter-width) 0 0;
      max-height: $line-height-computed;
      padding: 0;

      & .material-icons {
        color: rgba(123, 143, 174, 0.3);
      }

      &:hover .material-icons, &:active .material-icons {
        color: $body-color;
      }
  }

  .task-list-header-information {
    align-items: center;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

    .task-list-header-information-title {
      color: $blue-grey;
      // hack pour garder une taille à 100px
      flex-basis: 100px;
      max-width: 100px;
      min-width: 100px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;

      &.mod-user {
        //align-items: center;
        //display: flex;
        flex-basis: $grid-gutter-width;
        min-width: $grid-gutter-width;
        max-width: $grid-gutter-width;
      }

      &.mod-wide {
        // hack pour garder une taille à 200px
        flex-basis: 200px;
        max-width: 200px;
        min-width: 200px;
      }

      &.mod-small {
        // hack pour garder une taille à 30px
        flex-basis: 30px;
        max-width: 30px;
        min-width: 30px;
      }

      &:not(:first-child) {
        margin-left: $grid-gutter-width-sm;
      }
    }

    .task-list-header-information-title-icon {
      cursor: default;
      display: block;
    }

  .task-list-header-select-button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    left: -$grid-gutter-width-md;
    margin: 0;
    padding: $line-height-computed-xs $grid-gutter-width-xs;
    position: absolute;
    top: 10px;

    &.is-disabled,
    &.is-active.is-disabled {

    }

    &:hover &-icon,
    &:active &-icon,
    &.is-active &-icon {
      color: $btn-primary;
    }

    &-icon {
      color: #CCCCCC;
      display: block;
    }
  }
