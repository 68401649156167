.directory-section-action-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: $line-height-computed 0;
  padding: 0 $grid-gutter-width;
}

.directory-section-action-list-item {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: $border-radius;
  display: inline-block;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: (24 / 14);
  margin: 0;
  padding: 2px 8px;
  text-align: center;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):not(.is-loading):hover,
  &:not([disabled]):not(.is-loading):focus {
    background-color: lighten($brand-color-cyan, 5%);
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: not-allowed;
    background-color: #EAEAEA;
  }

  &.is-loading {
    color: #AAAAAA;
    cursor: wait;
    background-color: #EAEAEA;
  }

  &:not(:first-child) {
    margin-left: $grid-gutter-width-sm;
  }
}
