.task-modal-notes-form + .flatpickr-calendar, .task-modal-note-item .flatpickr-calendar {
  background: #ffffff;
  border: 1px solid  rgb(0 0 0 / 0.1);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.task-modal-notes-form {
  width: 30%;
}

.task-modal-notes-container {
  padding: 0 $grid-gutter-width;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $grid-gutter-width * 0.75;
  color: #7590af;
  font-family: "Roboto", sans-serif;

  .task-modal-note {

    .task-modal-note-title {
      display: flex;
      align-items: center;
      gap: $grid-gutter-width-xs;
      padding: 0 $grid-gutter-width-sm;

      .task-modal-note-action {
        visibility: hidden;
      }

      &:hover {
        background-color: rgba(0, 135, 255, 0.05) !important;
        color: $body-color;

        .task-modal-note-action {
          visibility: visible;
        }
      }

      .task-modal-note-title-icon {
        font-size: 18px;
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .task-modal-note-title-value {
        flex: 0 1 100%;
        display: flex;
        align-items: center;
        width: 100%;
        outline: 0;
        border: 0;
        background: transparent;
        text-align: left;
        height: $line-height-computed * 1.5;
        font-size: 14px;

        .task-modal-dialog-form-input {
          margin: 0;
        }
      }

      .task-modal-note-title-actions {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
      }
    }

    .task-modal-note-items {
      margin-bottom: $grid-gutter-width-xs;
      display: flex;
      flex-direction: column;
      gap: 3px;

      .task-modal-note-item {
        display: flex;
        gap: $grid-gutter-width-xs;
        align-items: center;

        .task-modal-note-action {
          visibility: hidden;
        }

        &:hover {
          background-color: rgba(0, 135, 255, 0.05) !important;
          color: $body-color;

          .task-modal-note-action {
            visibility: visible;
          }
        }

        .task-modal-note-item-text {
          flex: 0 1 100%;
          display: flex;
          align-items: center;
          height: $line-height-computed;
          font-size: 12px;

          .task-modal-dialog-form-input {
            margin: 0;
          }
        }

        .task-modal-note-item-actions {
          flex: 1 0 auto;
          display: flex;
          align-items: center;
        }

        .task-modal-note-item-date {
          background-color: transparent;
          border: none;
          border-radius: 4px;
          color: $blue-grey;
          cursor: pointer;
          font-family: $font-family;
          font-size: $font-size;
          font-weight: inherit;
          line-height: $line-height;
          margin: 0;
          padding: 0 $grid-gutter-width-xs;
          transition: 200ms ease-in-out background-color,
          200ms ease-in-out color;
          white-space: nowrap;

          &:not([disabled]):not(.is-loading):not(.is-static):hover,
          &:not([disabled]):not(.is-loading):not(.is-static):focus {
            background-color: rgba(0, 135, 255, 0.05) !important;
            color: $body-color;
          }

          &.is-loading {
            cursor: wait;
          }

          &[disabled] {
            cursor: not-allowed;
          }

          &.mod-readonly {
            cursor: default;
            font-family: inherit;
            font-size: 12px;
            font-weight: inherit;
            line-height: inherit;
          }

          &.is-static {
            cursor: inherit;
          }

          & > a {
            color: $blue-grey;
            text-decoration: underline;
            transition: 200ms ease-in-out color;

            &:hover, &:focus, &:active {
              color: $body-color;
            }
          }
        }

        .task-modal-note-item-date-input {
          flex: 1 1 auto;
          background-color: rgba(0, 135, 255, 0.05);
          border: none;
          border-radius: 4px;
          color: #516584;
          display: block;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.7142857143;
          margin: 0;
          padding: 0 6px;
          text-align: left;
          transition: 200ms ease-in-out background-color, 200ms ease-in-out color;
        }
      }
    }
  }

  .task-modal-note-input {
    background-color: #ffffff;
    border: solid 1px $light-grey-2;
    border-radius: 8px;
    color: $body-color;
    display: block;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: $line-height;
    overflow-y: scroll;
    padding: $line-height-computed-sm $grid-gutter-width-sm;
    max-height: 175px;
    width: 100%;
    text-align: left;
    transition: 200ms ease-in-out background-color,
    200ms ease-in-out border-color,
    200ms ease-in-out color;

    &:not([disabled]):not(.is-loading):not(.is-static):hover,
    &:not([disabled]):not(.is-loading):not(.is-static):focus {
      background-color: rgba(0, 135, 255, 0.05);
      border-color: transparent;
    }

    &[disabled],
    &.is-loading {
      background-color: rgba(81, 101, 132, 0.03);
      border-color: transparent;
      color: rgba(81, 101, 132, 0.5);
      cursor: not-allowed;
    }

    &.is-loading {
      cursor: wait;
    }

    &.is-static {
      border-color: transparent;
      color: $blue-grey;
      cursor: inherit;
      padding: 0 0 0 $grid-gutter-width-sm;
      overflow-y: auto;
    }

    &.is-hidden {
      display: none;
    }

    &.mod-button {
      cursor: pointer;
      overflow-y: auto;
    }
  }

  .task-modal-note-action {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    color: $blue-grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    transition: 200ms ease-in-out background-color, 200ms ease-in-out color;
    height: 24px;
    width: 24px;

    .material-icons {
      font-size: 18px;
    }

    &:hover, &:focus {
      background-color: $sidebar-hover-color;
      color: $body-color;
    }
  }

  .inline-edit-button-trigger {
    width: 100%;
    outline: 0;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: text;
    text-align: left;
    color: #7590af;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    display: block;
    height: 100%;
    font-size: inherit;

    &.mod-readonly {
      cursor: default;
    }
  }

  .inline-edit-input {
    width: 100%;
  }
}
