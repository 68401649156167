.compact-list {
  display: flex;
  flex-direction: column;
  margin: 0 0 -2px 0;
  padding: 0;
}

  .compact-list-item {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 0 0 2px 0;
    min-width: 0;
    padding: $line-height-computed-sm $grid-gutter-width-sm;

    &:hover, &:focus {
      background-color: $grey;
    }

    &.mod-placeholder {
      color: $dark-grey;
      font-size: 0.9em;
      font-style: italic;
      text-align: center;
    }

    &-avatar {
      background-color: $body-color;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
      color: #ffffff;
      display: block;
      font-size: 0.9em;
      font-weight: bold;
      height: $line-height-computed;
      line-height: $line-height;
      margin: 0 $grid-gutter-width-sm 0 0;
      overflow: hidden;
      padding: 0;
      text-align: center;
      width: $grid-gutter-width;
    }

    &-name {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: row;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-icon {
        margin-right: $grid-gutter-width-xs;
      }
    }

    &-input {
      flex: 1;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-action-list {
      flex-direction: row;
      margin: 0 0 0 $grid-gutter-width-sm;
      padding: 0;

      &-item {
        background: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        height: $line-height-computed;
        margin: 0;
        padding: 0;
        width: $grid-gutter-width;

        &:not(:first-child) {
          margin-left: $grid-gutter-width-sm;
        }

        &:hover, &:focus {
          background-color: $light-grey;
        }
          &:hover &-icon, &:focus &-icon {
            color: $body-color;
          }

        &-icon {
          color: $link-color;
        }
      }
    }
  }

.compact-list-input {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 0 2px 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;

  & > .ember-power-select-trigger {
    flex: 1;
  }

  &.mod-paddingless {
    padding: 0;
  }

  &.mod-centered {
    justify-content: center;
  }

  &.mod-with-label {
    flex: 1 1 80%;
  }

  &-label {
    background-color: $grey;
    padding: $line-height-computed-sm $grid-gutter-width-sm;

    &[for] {
      cursor: pointer;
    }
  }

  &-element {
    background-color: #ffffff;
    border: none;
    border-left: solid 2px $body-bg;
    color: $body-color;
    flex: 1;
    font-size: $font-size;
    line-height: $line-height;
    padding: $line-height-computed-sm $grid-gutter-width-sm;
  }

  &-button {
    background: #ffffff;
    border: none;
    color: $body-color;
    cursor: pointer;
    line-height: $line-height;
    margin: 0;
    padding: $line-height-computed-sm ($grid-gutter-width-sm + $grid-gutter-width-xs);

    &:hover, &:focus {
      background-color: $light-grey;
    }
      &:hover &-icon, &:focus &-icon {
        color: $body-color;
      }

    &[disabled] {
      background-color: rgba($body-color, 0.03);
      cursor: not-allowed;
      color: $link-color;
      .compact-list-input-button-icon {
        color: $link-color;
      }
    }

    &[disabled]:hover {
      background-color: rgba($body-color, 0.03);
      .compact-list-input-button-icon {
        color: $link-color;
      }
    }

    &-icon {
      color: $link-color;
      font-size: $font-size * 1.2;
      line-height: $line-height-computed / ($font-size * 1.2);
    }
  }

  &-button-block {
    align-items: center;
    background: #ffffff;
    border: none;
    border-radius: $border-radius;
    color: $body-color;
    cursor: pointer;
    display: flex;
    line-height: $line-height;
    margin: $line-height-computed-xs $grid-gutter-width-xs;
    padding: $line-height-computed-xs $grid-gutter-width-sm;

    &[disabled], &[disabled]:hover {
      background-color: transparent;
      cursor: not-allowed;
    }

    &:hover, &:focus {
      background-color: $light-grey;
    }
      &:hover &-icon, &:focus &-icon {
        color: $body-color;
      }

    &-icon {
      color: $body-color;
      font-size: $font-size * 1.2;
      line-height: $line-height-computed / ($font-size * 1.2);
      margin-right: $grid-gutter-width-xs;
    }

    &.mod-dark {
      background-color: $sidebar-color;
      color: #FFFFFF;

      &:hover, &:focus {
        background-color: $light-grey;
        color: $body-color;
      }

      &[disabled], &[disabled]:hover {
        background-color: $light-grey;
        color: #FFFFFF;
        cursor: not-allowed;

        .compact-list-input-button-block-icon {
          color: #FFFFFF;
        }
      }
    }
    &.mod-dark &-icon {
      color: #FFFFFF;
    }
    &.mod-dark:hover &-icon,  &.mod-dark:focus &-icon {
      color: $body-color;
    }

    .material-icons {
      margin-right: $grid-gutter-width-xs;
    }
  }

  &-loading {
    background-color: $grey;
    border-left: solid 2px $body-bg;
    color: $dark-grey;
    cursor: wait;
    flex: 1;
    font-style: italic;
    padding: $line-height-computed-sm $grid-gutter-width-sm;
    text-align: center;
  }
}
