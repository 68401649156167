.directory-navbar {
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-top: 2px solid $body-color;
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  margin: 0;
  padding: -2px 0 0 0;

  &.mod-full-width {
    flex: 1 1 auto;
  }

  &.mod-gray {
    background-color: $sidebar-background-color;
  }

  &.mod-blue {
    border-top-color: $brand-color-blue;
  }
  &.mod-green {
    border-top-color: $brand-color-green;
  }
  &.mod-orange {
    border-top-color: $brand-color-orange;
  }
  &.mod-red {
    border-top-color: $brand-color-red;
  }
}

.directory-navbar-separator {
  color: #AAAAAA;
}

.directory-navbar-spacer {
  margin-left: $grid-gutter-width;
}

/* ------ */
/* Racine */
/* ------ */

.directory-navbar-root,
.directory-navbar-item {
  background-color: transparent;
  border-top: none;
  color: #555555;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: (32 / 14);
  margin: 0;
  padding: 4px;
  text-decoration: none;

  &:not([data-disabled]) {
    cursor: pointer;
  }
}

.directory-navbar-root {
  height: 48px;
}

.directory-navbar-root-icon {
  color: $body-color;
  border-radius: 50%;
  padding: 8px;
  transition: 200ms ease-in-out background-color;

  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
  &.mod-red {
    color: $brand-color-red;
  }

  .directory-navbar-root:not([data-disabled]):hover &,
  .directory-navbar-root:not([data-disabled]):focus & {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

/* ------- */
/* Dossier */
/* ------- */

.directory-navbar-item {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  overflow: hidden;
  padding: 4px 0;

  &.mod-parameters {
    border-left: 1px solid #EAEAEA;
    flex: 0 0 auto;
    margin-left: 8px;
    padding: 4px 4px 4px 3px;

    & .directory-navbar-item-content {
      border-radius: 50%;
      padding: 8px;
    }

    & .directory-navbar-item-icon {
      margin: 0;
    }
  }

  &.mod-project {
    margin-left: $grid-gutter-width-xs;
  }

  &.mod-push-right {
    margin-right: auto;
  }

  &.mod-no-truncate {
    flex: 0 0 auto;
  }
}

.directory-navbar-item-content {
  align-items: center;
  background-color: transparent;
  border-radius: $border-radius;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: hidden;
  padding: 4px 12px;
  text-overflow: ellipsis;
  transition: 200ms ease-in-out background-color;
  white-space: nowrap;

  .directory-navbar-item:not([data-disabled]):hover &,
  .directory-navbar-item:not([data-disabled]):focus & {
    background-color: rgba(81, 101, 132, 0.1);
  }

  &.mod-favorite {
    padding-right: 6px;
  }

  .directory-navbar-item.mod-no-truncate & {
    flex: 0 0 auto;
  }
}

.directory-navbar-item-text {
  flex: 0 0 auto;

  .directory-navbar-item:not(.mod-no-truncate) & {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.directory-navbar-item-icon {
  color: $body-color;
  margin-right: $grid-gutter-width-xs;

  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
  &.mod-red {
    color: $brand-color-red;
  }
}

.directory-navbar-item-favorite {
  background-color: transparent;
  border: none;
  height: 28px;
  margin: 0 0 0 $grid-gutter-width-xs;
  padding: 0;
}

.directory-navbar-item-favorite-icon {
  background-color: transparent;
  border-radius: 50%;
  color: #AAAAAA;
  cursor: pointer;
  padding: 2px;
  transition: 200ms ease-in-out background-color;

  .directory-navbar-item-favorite:hover &,
  .directory-navbar-item-favorite:focus & {
    background-color: rgba(255, 255, 255, 0.75);
  }

  &.mod-yellow {
    color: $brand-color-yellow;
  }
}
