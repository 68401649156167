.sp-container {
  background-color: #ffffff;
  border-color: $link-color;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

  .sp-palette-container {
    padding: $line-height-computed-sm $grid-gutter-width-sm 300px;
  }

    .sp-palette {
      max-width: none;
      width: auto;

      &:not(:first-child) {
        margin-top: $line-height-computed-xs;
      }

      .sp-palette-row {
        display: flex;
        flex-direction: row;

        .sp-thumb-el {
          border-color: $link-color;
          float: none;
          height: $line-height-computed - 2px;
          margin: 0;
          width: $grid-gutter-width - 2px;

          &:hover, &:focus, &.sp-thumb-active {
            border-color: $body-color;
          }

          &:not(:last-child) {
            margin-right: $grid-gutter-width-xs;
          }
        }
      }
    }


.sp-preview-inner:after {
  color: #ffffff;
  content: "colorize";
  display: block;
  font-variant: normal;
  left: $grid-gutter-width-sm;
  position: absolute;
  text-decoration: inherit;
  top: 0;
  width: 24px;
  z-index: 2;

  font-smoothing: antialiased;
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.sp-replacer.sp-active .sp-preview-inner:after {
  display: none;
}

.sp-dd { // icône de dropdown
  display: none;
}
