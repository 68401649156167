.user-avatar {
  background-color: $body-color;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  display: block;
  font-size: $font-size;
  height: $line-height-computed;
  line-height: $line-height;
  position: relative;
  width: $grid-gutter-width;

  &:not(.mod-no-shadow) {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  }

  &-initials {
    color: #ffffff;
    font-size: 0.9em;
    font-weight: bold;
    max-height: $line-height-computed;
    overflow: hidden;
    text-align: center;
    max-width: $grid-gutter-width;
  }

  &-icon {
    background-color: #ffffff;
    border-radius: 50%;
    bottom: -$line-height-computed-xs / 2;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    display: block;
    height: $line-height-computed-sm;
    line-height: $line-height-computed-sm;
    position: absolute;
    right: -$grid-gutter-width-xs / 2;
    width: $grid-gutter-width-sm;

    &-glyph {
      color: #F1C33F;
      font-size: 0.9em;
      line-height: $line-height-computed-sm;
    }
  }
}
