.directory-hint {
  color: #AAAAAA;
  font-size: 14px;
  line-height: (32 / 14);
  margin: 0;
  padding: 0 $grid-gutter-width;
}

.directory-hint-content {
  background-color: #F6F6F6;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  line-height: (32 / 14);
  padding: 0 8px;

  & > em {
    font-style: normal;
    font-weight: 700;
  }
}

.directory-hint-highlight {
  color: #516584;
  font-weight: 700;
}

.directory-hint-button {
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
