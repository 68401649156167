.project-list {
  padding: 0 $grid-gutter-width;

  &.mod-flex {
    flex: 1;
  }

  &-header {
    &-title, &-button {
      font-size: $font-size * 1.5;
      line-height: $line-height-computed;
      margin: $line-height-computed 0;
      padding: 0;
    }

    &-title > a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-body {
    display: grid;
    grid-gap: $line-height-computed $grid-gutter-width;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    margin: 0;
    padding: 0 $grid-gutter-width;
  }
}
