@include global-placeholder-color(darken($link-color, 10));

/* -------------------------- */
/* Overrides des balises HTML */
/* -------------------------- */

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  background-color: #FCFCFC;
  color: $body-color;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0;
  overflow-y: hidden;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.is-blocking-scroll {
    overflow-y: hidden;
  }
}

a {
  color: $link-color;
}

input, textarea, select {
  &:disabled {
    background-color: rgba($body-color, 0.03);
    color: rgba($body-color, 0.5);
    cursor: wait;
  }
}

/* ------------------------------ */
/* Composants & classes du layout */
/* ------------------------------ */

.app-layout {
  display: grid;
  grid-template-columns: 225px 1fr;
  padding: 0;
  margin: 0;

  &.mod-mini-sidebar {
    grid-template-columns: 48px 1fr;
  }
}

.main-container {
  display: flex;
  flex: 1 0 auto;

  &.mod-column {
    flex-direction: column;
  }
}

.main-content {
  max-height: 100vh;
  overflow-y: scroll;

  &.mod-blue {
    background-color: $body-bg;
  }
}

.online-status,
.live-sync-status {
  background-color: $color-alert;
  border-radius: $border-radius 0 0 0;
  bottom: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  cursor: help;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  position: fixed;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 99;

  &-icon,
  &-text {
    color: #FCFFF5;
    font-size: $font-size;
    line-height: $line-height;
  }

  &-icon {
    font-size: 18px;
  }

  &-text {
    margin: 0;
    max-height: $line-height-computed;
    overflow: hidden;
    padding: 0;
    max-width: 0;
    white-space: nowrap;
    word-break: keep-all;
  }

  &:hover &-text {
    padding: 0 0 0 $grid-gutter-width-xs;
    max-width: 100%;
  }
}

.live-sync-status {
  background-color: $color-info;
  border-radius: $border-radius 0 0 $border-radius;
  bottom: $line-height-computed-lg;

  &-icon {
    animation: heartbeat 1s ease-in-out infinite;
  }
}

/* ---------------------------- */
/* Composants & classes en vrac */
/* ---------------------------- */

.blocked-account-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $grey;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .button {
    background-color: $color-info;
    border: none;
    border-radius: $border-radius;
    box-shadow: rgba(163, 177, 199, 0.3) 1px 1px 2px 0px;
    color: #ffffff;
    cursor: pointer;
    display: inline;
    font-weight: 700;
    margin: 0 auto;
    padding: $line-height-computed-xs $grid-gutter-width-sm;
  }
}

.container {
  margin-left: $grid-gutter-width;
  margin-right: $grid-gutter-width;
}

.progress-bar {
  flex: 1;
  height: $line-height-computed / 1.8;
  position: relative;

  &-bg {
    background-color: $grey;
    border: 2px solid $body-bg;
    border-radius: $border-radius;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    &:after {
      animation: move 3s linear infinite;
      background-image: linear-gradient(
          -45deg,
          #2DAAE1 25%,
          transparent 25%,
          transparent 50%,
          #2DAAE1 50%,
          #2DAAE1 75%,
          transparent 75%,
          transparent
      );
      background-size: 50px 50px;
      border-radius: $border-radius;
      content: "";
      display: none;
      overflow: hidden;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      z-index: 2;

      @keyframes move {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 50px 50px;
        }
      }
    }
  }

  &-inner {
    background-color: #2DAAE1;
    border-radius: $border-radius * 1.5;
    height: 100%;
    min-width: 14px;
    position: relative;
    z-index: 3;
  }

  &.is-pending &-inner {
    display: none;
  }
  &.is-pending &-bg:after {
    display: block;
  }
}

.button-link {
  align-items: center;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: $line-height;
  margin: 0;
  padding: 0;

  &:hover, &:focus {
    text-decoration: underline;
  }
}
.button-link-icon {
  margin-right: $grid-gutter-width-xs;
}

/* ---------- */
/* Animations */
/* ---------- */

@keyframes heartbeat {
  0% { opacity: 100%; }
  30% { opacity: 0; }
  60% { opacity: 100%; }
  100% { opacity: 100%; }
}
