.accordion{
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  &-item{
    flex: 1;
    cursor: pointer;
    padding: $line-height-computed-sm $grid-gutter-width/2 $line-height-computed-sm $grid-gutter-width/2;
    border-bottom: 1px solid $light-grey;

    &:last-child {
      border: 0;
    }

    &.mod-open{
      .accordion-item-header {
        padding: 0 0 $line-height-computed-sm 0;
      }
    }

    &-header{
      display: flex;
      align-items: center;

      &-label{
        flex: 0 1 100%;
        color: $link-color;
        text-transform: uppercase;
      }

      &-icon{
        flex: 0 0 auto;
      }
    }

    &-content{
      color: $body-color;
      position: relative;
    }
  }
}
