.callout {
  align-items: center;
  border-radius: $border-radius;
  border-style: solid;
  border-width: 1px;
  display: flex;
  font-size: $font-size;
  line-height: 1.714285714em;
  margin: 0;
  padding: 0.857142857em 1.714285714em;
  position: relative;

  &.mod-margin-bottom {
    margin-bottom: 1.714285714em;
  }

  &.mod-margin-top {
    margin-top: 1.714285714em;
  }

  &.mod-spaced {
    margin-left: $grid-gutter-width;
    margin-right: $grid-gutter-width;
  }

  &.mod-alert {
    background-color: lighten($color-alert, 38%);
    border-color: desaturate($color-alert, 33%);
    color: $body-color;

    & a, & .btn.mod-link {
      color: $body-color;

      &:hover, &:focus, &:active {
        color: $link-color;
      }
    }
  }

  &.mod-info {
    background-color: desaturate(lighten($color-info, 54%), 10%);
    border-color: desaturate(lighten($color-info, 50%), 10%);
    color: $body-color;

    & a, & .btn.mod-link {
      color: $body-color;

      &:hover, &:focus, &:active {
        color: $link-color;
      }
    }
  }

  &.mod-success {
    background-color: desaturate(lighten($color-success, 48%), 33%);
    border-color: desaturate(lighten($color-success, 50%), 33%);
    color: $body-color;

    & a, & .btn.mod-link {
      color: $body-color;

      &:hover, &:focus, &:active {
        color: $link-color;
      }
    }
  }

  &.mod-warning {
    background-color: lighten($color-warning, 38%);
    border-color: desaturate($color-warning, 33%);
    color: $body-color;

    & a, & .btn.mod-link {
      color: $body-color;

      &:hover, &:focus, &:active {
        color: $link-color;
      }
    }
  }

  &.mod-grey {
    background-color: #F9FAFB;
    border-color: desaturate(lighten($body-color, 50%), 33%);
    color: $body-color;

    & a, & .btn.mod-link {
      color: $body-color;

      &:hover, &:focus, &:active {
        color: $link-color;
      }
    }
  }

  &.mod-transparent {
    background-color: transparent;
    border: 0;
    color: $body-color;

    & a, & .btn.mod-link {
      color: $body-color;

      &:hover, &:focus, &:active {
        color: $link-color;
      }
    }
  }
}

.callout-icon  {
  margin-right: $grid-gutter-width-sm;

  &.mod-round {
    border-radius: 50%;
    padding: 0.2em;
  }

  &.mod-success {
    background-color: $color-success;
    color: white;
  }

  &.mod-alert {
    background-color: $color-alert;
    color: white;
  }
}

.callout-content {
  margin: 0;
  padding: 0;

  & > a {
    text-decoration: underline;
  }
}

.callout-close {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: (24 / 12);
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  position: absolute;
  right: 0;
  top: 0;
  transition: 200ms ease-in-out background-color;
  z-index: 1;

  &:hover, &:focus, &:active {
    background-color: #ffffff;
  }
}
