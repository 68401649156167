.avatar-select {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $screen-sm) {
    flex-direction: row;
  }

  &-initials,
  &-picture {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-sm) {
      flex: 1 1 50%;
    }
  }
}

  .avatar-select-item {
    display: flex;
    flex-direction: column;
    padding: $line-height-computed-sm $grid-gutter-width-sm;

    &.is-hidden {
      display: none;
    }

    &-container {
      display: block;
      height: $line-height-computed-md;
      margin-bottom: $line-height-computed-sm;
      position: relative;
      width: $grid-gutter-width-md;
    }

    &-content {
      background-color: $body-color;
      border: 2px solid #FFFFFF;
      border-radius: 50%;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
      color: #ffffff;
      display: block;
      font-size: 22px;
      height: $line-height-computed-md;
      line-height: ($line-height-computed-md - 4) / 22px;
      overflow: hidden;
      text-align: center;
      width: $grid-gutter-width-md;
    }

    &-image-container {
      display: block;
      height: $line-height-computed-md;
      margin-bottom: $line-height-computed-sm;
      position: relative;
      width: $grid-gutter-width-md;
    }

    &-image {
      background-color: #FFFFFF;
      background-image: url(https://via.placeholder.com/48x48);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border: 2px solid #FFFFFF;
      border-radius: 50%;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
      display: block;
      height: $line-height-computed-md;
      overflow: hidden;
      width: $line-height-computed-md;
    }
  }
