.search-modal {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  position: relative;
  width: 450px;
}

.search-modal-title {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;
}

/* ------------------- */
/* .search-modal-field */
/* ------------------- */

.search-modal-field {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width;
  position: relative;
}

.search-modal-field:after {
  background-color: #D7D7D7;
  content: "";
  display: block;
  height: 1px;
  margin: $line-height-computed-sm $grid-gutter-width-lg $line-height-computed-sm;
  position: relative;
}

.search-modal-field-input {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0;
  padding: 4px 8px 4px ($grid-gutter-width + 8px);
  text-align: center;
  transition: 200ms ease-in-out border-color;

  &:focus, &:active {
    border-color: #516584;
  }
}

.search-modal-field-icon {
  color: #D7D7D7;
  left: ($grid-gutter-width + 8px);
  position: absolute;
  top: ($line-height-computed-sm + 5px);
  z-index: 1;
}

.search-modal-field-hint {
  color: #AAAAAA;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: ($line-height-computed / 12px);
  text-align: center;
}

/* -------------------- */
/* .search-modal-result */
/* -------------------- */

.search-modal-result {
  margin: 0;
  max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  padding: 0;

  &.mod-longer {
    max-height: 300px;
  }
}

.search-modal-result-loader {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.search-modal-result-loader-icon {
  margin-right: $grid-gutter-width-xs;
  animation: loader-spin 1.2s linear infinite;
}

.search-modal-result-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm $line-height-computed-sm;
}

.search-modal-result-message {
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.search-modal-result-item {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: $border-radius;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: (20 / 14);
  overflow: hidden;
  margin: 0;
  padding: 5px $grid-gutter-width-sm;
  width: 100%;
  transition: 200ms ease-in-out background-color;

  &:hover, &:active {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.search-modal-result-item-icon {
  margin-right: $grid-gutter-width-xs;

  .search-modal-result-item.mod-task & {
    background-color: #ffffff;
    border: 2px solid $light-grey;
    border-radius: 50%;
    color: $light-grey;
    display: block;
    font-size: 20px;
    height: 24px;
    line-height: (24/22);
    width: 24px;

    &.is-checked {
      background-color: $color-success;
      border-color: $color-success;
      color: #ffffff;
    }
  }

  .search-modal-result-item.mod-directory & {
    color: $brand-color-cyan;

    &.mod-blue {
      color: $brand-color-blue;
    }
    &.mod-green {
      color: $brand-color-green;
    }
    &.mod-orange {
      color: $brand-color-orange;
    }
  }
}

.search-modal-result-item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-modal-result-item-ref {
  @include directory-reference;
}

/* ---------------------------- */
/* .search-modal-result-section */
/* ---------------------------- */

.search-modal-result-section {
  margin: 0 0 $line-height-computed-sm 0;
}

.search-modal-result-section-title {
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-transform: uppercase;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
