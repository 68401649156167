// couleurs

$body-bg: #F2F4F7;
$body-color: #516584;

$sidebar-color: #7B8FAE;

$primary-brand-color: #e12f26;
$secondary-brand-color: #664632;

$link-color: #CBD2DE;
$light-grey: #E7E7E7;
$grey: #F9F9F9;
$dark-grey: #B3B3B3;
$blue-grey: darken(#859db8, 5%);
$light-grey-2: darken(#E9E9EB, 5%);
$pdf-report-color: #18A096;

$color-success: #85cc00;
$color-alert: #ea4646;
$color-warning: #F1C33F;
$color-info: #00AECC;

$btn-primary: #2DAAE1;

$sidebar-background-color: #F6F6F6;
$sidebar-border-color: #EAEAEA;
$sidebar-hover-color: rgba(81, 101, 132, 0.1);

$brand-color-cyan: $color-info;
$brand-color-blue: #2D7FF9;
$brand-color-green: #20C933;
$brand-color-orange: #FFA100;
$brand-color-red: #D9001B;
$brand-color-yellow: #FFCB00;

// z-indexes

$modal-base-zindex: 20;
$modal-dialog-zindex: 21;
$flash-message-list-zindex: 30;

// divers

$border-radius: 6px;
$border-radius-sm: $border-radius / 2;

$font-family: 'Roboto', sans-serif;

$font-size: 14px;

$line-height-computed: 24px;
$line-height: $line-height-computed / $font-size; // (24/14)

$line-height-computed-xs: $line-height-computed / 4;
$line-height-computed-sm: $line-height-computed / 2;
$line-height-computed-md: $line-height-computed * 2;
$line-height-computed-lg: $line-height-computed * 4;

$grid-gutter-width: 24px;
$grid-gutter-width-xs: $grid-gutter-width / 4;
$grid-gutter-width-sm: $grid-gutter-width / 2;
$grid-gutter-width-md: $grid-gutter-width * 2;
$grid-gutter-width-lg: $grid-gutter-width * 4;

$screen-xss: 360px;
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xlg: 1600px;
