.env-disclaimer {
  background-color: lighten($color-warning, 25%);
  border-radius: $border-radius 0 0 $border-radius;
  bottom: $line-height-computed-md;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  cursor: help;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  position: fixed;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 99;

  &.is-hidden {
    display: none;
  }

  &-icon,
  &-text {
    color: darken($color-warning, 25%);
    font-size: $font-size;
    line-height: $line-height;
  }

  &-icon {
    font-size: 18px;
  }

  &-text {
    margin: 0;
    max-height: $line-height-computed;
    overflow: hidden;
    padding: 0;
    max-width: 0;
    white-space: nowrap;
    word-break: keep-all;
  }

  &:hover &-text {
    padding: 0 0 0 $grid-gutter-width-xs;
    max-width: 100%;
  }
}
