.sidebar {
  align-self: stretch;
  background-color: $sidebar-background-color;
  border-right: solid 1px $sidebar-border-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

/* ------------- */
/* .sidebar-menu */
/* ------------- */

.sidebar-menu-item {
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px $sidebar-border-color;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex: 0 0 (2 * $line-height-computed);
  flex-direction: row;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: (2 * $line-height-computed) / 16px;
  overflow: hidden;
  padding: 0 $grid-gutter-width;
  text-align: left;
  text-decoration: none;
  transition: 200ms ease-in-out background-color;

  &.is-active {
    background-color: #FFFFFF
  }

  &:hover, &:active {
    background-color: $sidebar-hover-color;
  }
}

.sidebar-menu-item-icon {
  margin-right: $grid-gutter-width / 3;
  position: relative;
}

.sidebar-menu-item-icon-badge {
  background-color: $brand-color-cyan;
  border-radius: 50%;
  bottom: -2px;
  display: block;
  height: 10px;
  position: absolute;
  right: -2px;
  text-align: center;
  width: 10px;
  z-index: 1;
}

/* ---------------- */
/* .sidebar-section */
/* ---------------- */

.sidebar-section {
  padding: 16px 0 7px;
  border-bottom: solid 1px $sidebar-border-color;

  &.mod-spaced {
    margin-top: auto;
  }

  &.mod-no-border {
    border-bottom: none;
  }
}

.sidebar-section-head {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 $grid-gutter-width-sm 0 $grid-gutter-width;
}

.sidebar-section-head-title {
  color: #AAAAAA;
  font-size: 12px;
  line-height: (32 / 12);
  height: 32px;
  text-transform: uppercase;
}

.sidebar-section-head-button {
  align-items: center;
  display: flex;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  flex-direction: row;
  font-family: inherit;
  height: 32px;
  margin: 0 0 0 auto;
  padding: 0;
  width: 32px;
  transition: 200ms ease-in-out background-color;

  &:hover, &:focus {
    background-color: $sidebar-hover-color;
  }
}

.sidebar-section-head-button-icon {
  background-color: $brand-color-cyan;
  border-radius: 50%;
  color: #ffffff;
  font-size: 16px;
  height: 16px;
  margin: auto auto;
  width: 16px;
}

.sidebar-section-item {
  display: block;
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: (2 * $line-height-computed) / 16px;
  overflow: hidden;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  text-decoration: none;
  width: 100%;

  &:hover .sidebar-section-item-content,
  &:active .sidebar-section-item-content {
    background-color: $sidebar-hover-color;
  }
  &.is-active .sidebar-section-item-content {
    background-color: #ffffff;
  }
}

.sidebar-section-item-content {
  align-items: center;
  background-color: transparent;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  height: (2 * $line-height-computed) - $line-height-computed-sm;
  padding: 0 $grid-gutter-width-sm;
}

.sidebar-section-item-icon {
  margin-right: $grid-gutter-width / 3;

  &.mod-private {
    color: $brand-color-blue;
  }
  &.mod-team {
    color: $brand-color-green;
  }
  &.mod-workspace {
    color: $brand-color-orange;
  }
}

/* ------------- */
/* .sidebar-user */
/* ------------- */

.sidebar-user {
  align-items: center;
  border-bottom: solid 1px $sidebar-border-color;
  border-top: solid 1px $sidebar-border-color;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 7px 8px 7px $grid-gutter-width-sm;
}

.sidebar-user-initials,
.sidebar-user-avatar {
  background-color: $body-color;
  border: solid 1px #D7D7D7;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed;
  line-height: (22 / 12);
  overflow: hidden;
  text-align: center;
  width: $grid-gutter-width;
}

.sidebar-user-identity {
  margin-left: $grid-gutter-width-sm;
}

.sidebar-user-button {
  align-items: center;
  display: flex;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  flex-direction: row;
  font-family: inherit;
  justify-content: center;
  height: 32px;
  margin: 0 0 0 auto;
  padding: 0;
  width: 32px;
  transition: 200ms ease-in-out background-color;

  &:hover, &:focus {
    background-color: $sidebar-hover-color;
  }
}

.sidebar-user-button-icon {
}

/* ------------------ */
/* .sidebar-workspace */
/* ------------------ */

.sidebar-workspace {
  align-items: center;
  border-bottom: solid 1px $sidebar-border-color;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.sidebar-workspace-logo {
  background-color: transparent;
  border: none;
  color: #ffffff;
  flex: 0 0 auto;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: (40 / 12);
  overflow: hidden;
  text-align: center;
  width: 40px;
}

.sidebar-workspace-title {
  font-size: 16px;
  font-weight: 500;
  line-height: (24 / 16);
  overflow: hidden;
  margin-left: $grid-gutter-width-sm;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-workspace-button {
  align-items: center;
  display: flex;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  height: 32px;
  margin: 0 0 0 auto;
  padding: 0;
  width: 32px;
  transition: 200ms ease-in-out background-color;

  &:hover, &:focus {
    background-color: $sidebar-hover-color;
  }
}

/* --------------- */
/* .sidebar-search */
/* --------------- */

.sidebar-search {
  background-color: transparent;
  border: none;
  border-bottom: solid 1px $sidebar-border-color;
  margin: 0;
  padding: ($line-height-computed-xs + 2px) $grid-gutter-width-sm ($line-height-computed-xs + 1px);
  position: relative;
  text-align: left;
}

.sidebar-search-button {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #d7d7d7;
  border-radius: $border-radius;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  line-height: $line-height;
  margin: 0;
  padding: ($line-height-computed-xs / 2) $grid-gutter-width-xs;
  position: relative;
  text-align: left;
  transition: 200ms ease-in-out border-color;
  width: 100%;

  &:hover, &:focus {
    border-color: $body-color;
  }
}

.sidebar-search-button-icon {
  color: #aaaaaa;
  font-size: 20px;
}

.sidebar-search-button-text {
  color: #aaaaaa;
  flex: 1 1 auto;
  font-size: 13px;
  line-height: ($line-height-computed / 13px);
  margin-left: $grid-gutter-width-sm;
}
