.task-modal-user-in-charge {
  width: 300px;

  &.mod-large {
    width: auto;
    min-width: 400px;
    max-width: 500px;
  }
}

/* -------------- */
/* Milestone list */
/* -------------- */

.task-modal-user-in-charge-list {
  border-bottom: solid 1px #D7D7D7;
  margin: 0 0 $line-height-computed-xs;
  max-height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $line-height-computed-xs 0;
}

.task-modal-user-in-charge-list-item,
.task-modal-user-in-charge-suggestions-item {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  text-align: left;
  transition: 200ms ease-in-out background-color;
  width: 100%;

  &.is-selected {
    font-weight: 700;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]):not(.is-loading):hover,
  &:not([disabled]):not(.is-loading):focus {
    background-color: $body-bg;
  }
}

.task-modal-user-in-charge-list-item-initials,
.task-modal-user-in-charge-suggestions-item-initials {
  background-color: $sidebar-border-color;
  border: 1px solid #ffffff;
  border-radius: 50%;
  flex: 0 0 auto;
  font-family: inherit;
  height: 24px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 24px;
}

.task-modal-user-in-charge-list-item-initials-text,
.task-modal-user-in-charge-suggestions-item-initials-text {
  border-radius: 50%;
  bottom: 0;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  line-height: (22 / 12);
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  z-index: 1;
}

.task-modal-user-in-charge-list-item-text,
.task-modal-user-in-charge-suggestions-item-name {
  flex: 1 1 auto;
  margin: 0 auto 0 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-modal-user-in-charge-list-item-icon {
  flex: 0 0 auto;
  transition: 200ms ease-in-out opacity;

  &.mod-add {
    color: $color-success;
    opacity: 0;
  }

  .task-modal-user-in-charge-list-item.is-selected & {
    color: $brand-color-cyan;
  }

  .task-modal-user-in-charge-list-item:hover &.mod-add,
  .task-modal-user-in-charge-list-item:focus &.mod-add {
    opacity: 1;
  }
}

/* --------------- */
/* Suggestion list */
/* --------------- */

.task-modal-user-in-charge-suggestions {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  margin: 0 0 $line-height-computed-xs 0;
  max-height: 170px;
  min-height: $line-height-computed + $line-height-computed-sm;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 0;
}

.task-modal-user-in-charge-suggestions-loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.task-modal-user-in-charge-suggestions-item {
  flex: 1;
  width: auto;
}

.task-modal-user-in-charge-suggestions-item-email {
  color: $blue-grey;
  font-size: 12px;
  line-height: ($line-height-computed / 12px);
  margin-left: $grid-gutter-width-xs;
}
