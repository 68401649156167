.header-login {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  height: $line-height-computed * 3;
  padding: 0 $line-height-computed;

  @include media-breakpoint-down(xs) {
    justify-content: center;
  }
}

  .header-login-logo {
    display: flex;
    text-decoration: none;
    flex: 1 0 0;
  }
    .header-login-logo-slogan {
      margin-left: $grid-gutter-width;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

  .header-login-nav {
    display: flex;
    margin-left: auto;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

    .header-login-nav-button {
      border: none;
      border-radius: 5px;
      cursor: pointer;
      display: block;
      font-family: $font-family;
      font-size: $font-size * 1.142857143;
      font-weight: bold;
      line-height: 1.5em;
      padding: 6px $grid-gutter-width;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      margin-right: $grid-gutter-width-sm;

      &:last-child {
        margin-right: 0;
      }

      &.mod-login {
        background-color: #2DAAE1;
        color: #FFFFFF;
      }

      &.mod-register {
        background-color: #F2F2F2;
        color: #516584;
      }
    }
