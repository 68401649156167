.task-toolbar {
  display: flex;
  flex-direction: row;
  margin: 0 0 $line-height-computed-sm 0;
  padding: 0 $grid-gutter-width;
}

  .task-toolbar-selection {
    color: $blue-grey;
    display: block;
    margin: 0;
    padding: 0 0 0 $grid-gutter-width-sm;
  }

  .task-toolbar-action-list {
    display: flex;
    flex-direction: row;
  }

    .task-toolbar-action-list-item {
      background-color: #ffffff;
      border: none;
      border-radius: $border-radius;
      color: $sidebar-color;
      cursor: pointer;
      display: block;
      font-size: $font-size;
      line-height: $line-height;
      margin: 0;
      padding: 0 $grid-gutter-width-sm;
      position: relative;
      text-align: center;
      text-decoration: none;

      &[disabled], &.mod-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:not(:last-child) {
        margin-right: $grid-gutter-width-sm;
      }

      &:not(.mod-disabled):not([disabled]):hover {
        color: #ffffff;
      }
    }
