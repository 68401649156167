.generic-content-container {
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: rgba(163, 177, 199, 0.3) 1px 1px 2px 0px;
  margin: $line-height-computed-lg auto;
  max-width: 50%;
  min-width: 350px;
  padding: $line-height-computed-sm $grid-gutter-width-sm;

  &.mod-no-padding {
    padding: 0;
  }
}
