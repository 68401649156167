.project-task-toolbar {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 0 $line-height-computed-sm 0;
  padding: 0 $grid-gutter-width;
}

.project-task-toolbar-loader {
  margin: $line-height-computed-lg $grid-gutter-width 0;
  padding: 0;
}

.project-task-toolbar-item {
  background-color: transparent;
  border: none;
  color: $sidebar-color;
  cursor: pointer;
  margin: 0;
  padding: 4px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition:
    200ms ease-in-out background-color,
    200ms ease-in-out color;

  &:not(:first-child) {
    margin-left: $grid-gutter-width-xs;
  }

  &.mod-left {
    margin-right: auto;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]).is-active {
    color: #ffffff;
  }
}

.project-task-toolbar-item-icon {
  background-color: transparent;
  border-radius: 50%;
  margin: 0;
  padding: 8px;

  .project-task-toolbar-item:not([disabled]):hover &,
  .project-task-toolbar-item:not([disabled]):focus & {
    background-color: #ffffff;
  }

  .project-task-toolbar-item:not(.is-loading) &.mod-loader {
    display: none;
  }
  .project-task-toolbar-item.is-loading &:not(.mod-loader) {
    display: none;
  }
  .project-task-toolbar-item.is-loading &.mod-loader {
    animation: loader-spin 1.2s linear infinite;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
