.project-pdf-report-header {
  margin-bottom: $line-height-computed;
}
.project-pdf-report-header-logo  {
  text-align: center;
}
.project-pdf-report-header-title-main {
  font-size: $line-height-computed;
  margin: 0;
  text-align: center;
}
.project-pdf-report-header-title-sub {
  margin: 0 0 $line-height-computed 0;
  text-align: center;

  & > span {
    background: $pdf-report-color;
    color: #FFFFFF;
    display: inline-block;
    padding: 0 (2 * $line-height-computed);
  }
}

.project-pdf-report-milestone-table {
  border: 1px solid #516584;
  border-collapse: collapse;
  font-size: 0.785714286rem;
  width: 100%;
}

.project-pdf-report-milestone-table tr,
.project-pdf-report-milestone-table th,
.project-pdf-report-milestone-table td {
  border: 1px solid #516584;
  border-collapse: collapse;
  vertical-align: top;
}

.project-pdf-report-milestone-table th {
  text-align: left;
}

.project-pdf-report-milestone-table th,
.project-pdf-report-milestone-table td {
  padding: 6px;
}

.project-pdf-report-milestone-table th,
.project-pdf-report-milestone-table-task {
  color: $pdf-report-color;
}

.project-pdf-report-milestone-table-col1 {
  width: 24px;
}
.project-pdf-report-milestone-table-col2 {
  width: auto;
}
.project-pdf-report-milestone-table-col3 {
  width: 25%;
}
.project-pdf-report-milestone-table-col4 {
  text-align: center;
  width: 12%;
}
.project-pdf-report-milestone-table-col5 {
  width: 20%;
}

.project-pdf-report-milestone-table-icon {
  background: transparent;
  border: 2px solid $light-grey;
  border-radius: 50%;
  color: $light-grey;
  display: block;
  font-family: "Material Icons";
  line-height: 1;
  margin-top: 2px;

  &.is-checked {
    background: $color_success;
    border-color: $color_success;
    color: #FFFFFF;
  }
}

.project-pdf-report-milestone-table-custom-fields {
  display: grid;
  grid-column-gap: $grid-gutter-width;
  grid-row-gap: 0;
  grid-template-columns: fit-content(50%) 1fr;
  margin-top: $line-height-computed;

  & > label {
    font-weight: bold;
    line-height: $line-height-computed;
  }

  & .task-modal-field-list-item-value {
    font-weight: normal !important;
    font-size: ($font-size * 0.785714286) !important;
    line-height: $line-height-computed !important;
    padding-left: 0px !important;
  }

  & .task-modal-field-list-item-value[data-type="list"] {
    font-size: ($font-size * 0.714285714) !important;
    padding: 0px 4px !important;
  }
}
