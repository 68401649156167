.task-modal-checklists-container {
  padding: 0 $grid-gutter-width;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $grid-gutter-width * 0.75;
  color: #7590af;
  font-family: "Roboto", sans-serif;

  .task-modal-checklist {

    /*border-bottom: 1px solid rgba(123, 143, 174, 0.3);
    padding-bottom: $grid-gutter-width-sm;

    &:last-child {
      border: 0;
    }*/

    .task-modal-checklist-title {
      display: flex;
      align-items: center;
      gap: $grid-gutter-width-xs;
      padding: 0 $grid-gutter-width-sm;

      .task-modal-checklist-action {
        visibility: hidden;
      }

      &:hover {
        background-color: rgba(0, 135, 255, 0.05) !important;
        color: $body-color;

        .task-modal-checklist-action {
          visibility: visible;
        }
      }

      .task-modal-checklist-title-icon {
        font-size: 18px;
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .task-modal-checklist-title-value {
        flex: 0 1 100%;
        display: flex;
        align-items: center;
        width: 100%;
        outline: 0;
        border: 0;
        background: transparent;
        text-align: left;
        height: $line-height-computed * 1.5;
        font-size: 14px;

        .task-modal-dialog-form-input {
          margin: 0;
        }
      }

      .task-modal-checklist-title-actions {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
      }
    }

    .task-modal-checklist-items {
      margin-bottom: $grid-gutter-width-xs;
      margin-left: $grid-gutter-width-sm;
      display: flex;
      flex-direction: column;
      gap: 3px;

      .task-modal-checklist-item {
        display: flex;
        gap: $grid-gutter-width-xs;
        padding: 0 $grid-gutter-width-sm;
        align-items: center;

        .task-modal-checklist-action {
          visibility: hidden;
        }

        &:hover {
          background-color: rgba(0, 135, 255, 0.05) !important;
          color: $body-color;

          .task-modal-checklist-action {
            visibility: visible;
          }
        }

        .task-modal-checklist-item-check {
          flex: 1 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          .task-modal-checklist-item-check-button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            flex: 0 0 auto;
            margin: 0 $grid-gutter-width-xs 0 0;
            overflow: hidden;
            padding: calc($line-height-computed-xs / 2) calc($grid-gutter-width-xs / 2);
            transition: 200ms ease-in-out background-color;

            &:not([disabled]):not(.is-loading):not(.is-static):hover,
            &:not([disabled]):not(.is-loading):not(.is-static):focus {
              background-color: $sidebar-hover-color;
            }

            &.is-loading {
              cursor: wait;
            }

            &[disabled] {
              cursor: not-allowed;
            }

            &.is-static {
              cursor: inherit;
            }

            &:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):hover .task-modal-checklist-item-check-icon,
            &:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):focus .task-modal-checklist-item-check-icon {
              background-color: $brand-color-cyan;
              border-color: $brand-color-cyan;
              color: #ffffff;
            }

            &.is-checked .task-modal-checklist-item-check-icon {
              background-color: $color-success;
              border-color: $color-success;
              color: #ffffff;
            }

            .task-modal-checklist-item-check-icon {
              background-color: #ffffff;
              border: 2px solid $brand-color-cyan;
              color: $blue-grey;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 10px;
              height: 14px;
              width: 14px;
              transition: 200ms ease-in-out background-color,
              200ms ease-in-out border-color,
              200ms ease-in-out color;
            }
          }
        }

        .task-modal-checklist-item-name {
          flex: 0 1 100%;
          display: flex;
          align-items: center;
          height: $line-height-computed;
          font-size: 12px;

          .task-modal-dialog-form-input {
            margin: 0;
          }
        }

        .task-modal-checklist-item-actions {
          flex: 1 0 auto;
          display: flex;
          align-items: center;
        }
      }
    }

    .task-modal-checklist-add-form {
      margin-left: $grid-gutter-width-sm;
      padding: 0 $grid-gutter-width-sm;

      .task-modal-dialog-form-input {
        margin: 0;
      }

      .task-modal-checklist-add-form-actions {
        display: flex;
        gap: $grid-gutter-width-xs;

        .task-modal-dialog-action-list-item, .task-modal-section-action {
          flex: 0 1 auto;
          margin: 0;
        }
      }
    }
  }

  .task-modal-checklist-input {
    background-color: #ffffff;
    border: solid 1px $light-grey-2;
    border-radius: 8px;
    color: $body-color;
    display: block;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: $line-height;
    overflow-y: scroll;
    padding: $line-height-computed-sm $grid-gutter-width-sm;
    max-height: 175px;
    width: 100%;
    text-align: left;
    transition: 200ms ease-in-out background-color,
    200ms ease-in-out border-color,
    200ms ease-in-out color;

    &:not([disabled]):not(.is-loading):not(.is-static):hover,
    &:not([disabled]):not(.is-loading):not(.is-static):focus {
      background-color: rgba(0, 135, 255, 0.05);
      border-color: transparent;
    }

    &[disabled],
    &.is-loading {
      background-color: rgba(81, 101, 132, 0.03);
      border-color: transparent;
      color: rgba(81, 101, 132, 0.5);
      cursor: not-allowed;
    }

    &.is-loading {
      cursor: wait;
    }

    &.is-static {
      border-color: transparent;
      color: $blue-grey;
      cursor: inherit;
      padding: 0 0 0 $grid-gutter-width-sm;
      overflow-y: auto;
    }

    &.is-hidden {
      display: none;
    }

    &.mod-button {
      cursor: pointer;
      overflow-y: auto;
    }
  }

  .task-modal-checklist-action {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    color: $blue-grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    transition: 200ms ease-in-out background-color, 200ms ease-in-out color;
    height: 24px;
    width: 24px;

    .material-icons {
      font-size: 18px;
    }

    &:hover, &:focus {
      background-color: $sidebar-hover-color;
      color: $body-color;
    }
  }

  .inline-edit-button-trigger {
    width: 100%;
    outline: 0;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: text;
    text-align: left;
    color: #7590af;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    display: block;
    height: 100%;
    font-size: inherit;

    &.mod-readonly {
      cursor: default;
    }
  }

  .inline-edit-input {
    width: 100%;
  }
}


