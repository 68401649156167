// Structure

.ember-modal-dialog {
  z-index: $modal-dialog-zindex;
  position: fixed;

  &.emd-in-place {
    position: static;
  }
}

.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .ember-modal-dialog {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ember-modal-wrapper.emd-animatable {
  &.emd-wrapper-target-attachment-center {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $modal-base-zindex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.emd-wrapper-target-attachment-center .ember-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ember-modal-dialog {
    position: relative;
  }
}

// Apparence

.ember-modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $modal-base-zindex;

  &.translucent {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.ember-modal-dialog {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  width: 80%;
  position: relative;

  &.mod-higher {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: $screen-sm) {
    width: 50%;
  }
  @media (min-width: $screen-md) {
    width: 40%;
  }
  @media (min-width: $screen-lg) {
    width: 30%;
  }
  @media (min-width: $screen-xlg) {
    width: 20%;
  }
}

/* ------ */
/* Header */
/* ------ */

.ember-modal-dialog-header {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;

  .ember-modal-dialog.mod-wide & {
    font-size: 18px;
    line-height: ($line-height-computed-md / 18px);
    text-align: center;
  }
}

/* ------- */
/* Content */
/* ------- */

.ember-modal-dialog-content {
  background-color: transparent;
  margin: $line-height-computed-sm 0;
  padding: 0 $grid-gutter-width-sm;
  position: relative;

  &.mod-paddingless {
    padding: 0;
  }

  &.mod-w500 {
    max-width: 500px;
  }
}

.ember-modal-dialog-content-loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  bottom: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 1px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 10;
}

.ember-modal-dialog-footer {
  border-top: solid 1px #D7D7D7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.ember-modal-dialog-footer-button {
  background-color: $brand-color-cyan;
  border: 1px solid $brand-color-cyan;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: inherit;
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  padding: 2px 8px;
  margin: 0;
  text-decoration: none;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;

  &.mod-push-left {
    margin-right: auto;
  }

  &.mod-secondary {
    background-color: transparent;
    color: $brand-color-cyan;

    &:not([disabled]):hover, &:not([disabled]):focus {
      color: #FFFFFF;
    }
  }

  &:not([disabled]):hover, &:not([disabled]):focus {
    background-color: fade_out($brand-color-cyan, 0.25);
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: default;
    background-color: #EAEAEA;
    opacity: 0.6;
  }
}
