.project-navbar {
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-top: 2px solid $brand-color-cyan;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin: 0;
  padding: -2px 0 0 0;

  &.mod-shadow {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: $line-height-computed;
  }

  & > .directory-navbar {
    margin-top: -2px;
  }
}

.project-navbar-button {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: (24 / 14);
  padding: 2px 8px;
  margin: 0;
  text-decoration: none;
  transition: 200ms ease-in-out background-color;

  &:hover, &:focus {
    background-color: fade_out($brand-color-cyan, 0.25);
  }
}

.project-navbar-member-container {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0 0 0 6px;
  padding: 4px 8px;
  transition: 200ms ease-in-out background-color;

  &.mod-spaced {
    margin-right: $grid-gutter-width-xs;
  }

  &:hover, &:focus {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.project-navbar-members {
  background-color: transparent;
  border: none;
  color: #AAAAAA;
  font-family: inherit;
  margin: 0 8px 0 0;
  padding: 0;
  position: relative;
}

.project-navbar-members-icon {

}

.project-navbar-members-counter {
  bottom: -2px;
  display: block;
  position: absolute;
  font-size: 10px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}
