.login-container {
  margin: $line-height-computed * 3 auto;
  width: 580px;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    width: 100%;
  }

  &.mod-register,
  &.mod-error {
    display: flex;
    justify-content: space-between;
    width: 960px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.login-form {
  position: relative;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.149019607843137);
  padding: (42 + $line-height-computed) ($line-height-computed * 4) $line-height-computed ($line-height-computed * 4);

  @include media-breakpoint-down(sm) {
    padding: (42 + $line-height-computed) $grid-gutter-width $line-height-computed;
  }

  .login-container.mod-register & {
    width: 55%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &:before {
    position: absolute;
    content: url(../assets/icon-animal.svg);
    width: 70px;
    height: 70px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  &.mod-login {
    &:before {
      content: url(../assets/icon-femme.svg);
    }
  }

  &.mod-register {
    &:before {
      content: url(../assets/icon-homme.svg);
    }

    .login-form-footer-item-link{
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }


  &-header {
    margin: 0;
    padding: 0;

    &-heading {
      font-size: 36px;
      font-weight: 300;
      line-height: 36px;
      margin: 0 0 36px 0;
      padding: 0;
      text-align: center;
    }
  }

  &-row {
    margin-bottom: $line-height-computed;

    &.mod-text-center {
      text-align: center;
    }

    &:last-of-type {
      margin-bottom: $line-height-computed-sm;
    }

    &-label {
      display: block;
      font-size: 18px;
      line-height: $line-height-computed;
      margin-bottom: 6px;
    }

    &-input {
      display: block;
      background: $grey;
      border-color: $link-color;
      border-style: solid;
      border-width: 0 0 1px 0;
      color: $body-color;
      font-family: $font-family;
      font-size: $font-size;
      line-height: $line-height-computed;
      padding: 12px $line-height-computed;
      width: 100%;
    }

    &-help {
      color: $sidebar-color;
      margin: 0;
      margin: 6px 0 0 0;
      font-size: 12px;
      line-height: 2em;
      font-style: italic;
    }

    &-button {
      background: $btn-primary;
      border: none;
      border-radius: 5px;
      color: #FFFFFF;
      cursor: pointer;
      display: block;
      font-family: $font-family;
      font-size: $font-size * 1.142857143;
      font-weight: bold;
      line-height: 1.5em;
      padding: 0.75em 0;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &.mod-inline {
        display: inline-block;
        margin: auto;
        padding: 0.75em $line-height-computed;
        width: auto;
      }

      & > span {
        display: none;
      }

      & .spinner-container {
        padding: 0;
      }

      &:disabled {
        background: $light-grey;
        cursor: wait;
        color: $dark-grey;

        & > span {
          display: inline;

          &:before {
            content: "\f15b";
            animation: infinite-spinning 1s infinite linear;
          }
        }
      }
    }
  }

  &-text {
    margin-bottom: $line-height-computed-sm;
    font-size: 1.2em;

    &.mod-centered {
      text-align: center;
    }
  }

  &-footer {
    display: flex;

    &-item {
      flex-grow: 1;

      &.mod-text-right {
        text-align: right;
      }

      &.mod-text-center {
        text-align: center;
      }

      &-link {
        color: $body-color;
        text-decoration: none;

        &:hover, &:focus, &:active {
          color: $body-color;
          text-decoration: underline;
        }
      }
    }
  }
}

.login-box {
  align-self: start;
  background-color: #F9F9F9;
  border-radius: 12px;
  padding: $line-height-computed $grid-gutter-width;
  width: calc(45% - #{$line-height-computed});

  @include media-breakpoint-down(sm) {
    display: none;
    margin-top: $line-height-computed;
    width: 100%;
  }
}

  .login-box-title {
    font-weight: 700;
    font-size: 18px;
    line-height: $line-height-computed;
  }

  .login-box-quote {
    background-color: #FFFFFF;
    margin: 0;
    padding: $line-height-computed $grid-gutter-width;
    margin-bottom: $line-height-computed;
  }
    .login-box-quote-title {
        font-weight: 700;
        font-size: 18px;
        span {
          color: $btn-primary;
        }
    }
    .login-box-quote-info {
      font-size: 18px;
      font-style: italic;
    }

.login-register-account {
  display: flex;
  align-items: center;
  border: 1px solid $blue-grey;
  border-radius: 8px;
  margin-top: $line-height-computed-md;
  padding: $line-height-computed-sm $grid-gutter-width;
}

  .login-register-account-text {
    color: $blue-grey;
    font-weight: 700;
  }

  .login-register-account-button {
    align-self: center;
    border-radius: 5px;
    margin-left: auto;
  }

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
