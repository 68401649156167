.directory-invite-modal {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  min-width: 0;
  width: 400px;
}

.directory-invite-modal-title {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;
}

/* ------------ */
/* Search input */
/* ------------ */

.directory-invite-modal-search {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width;
  position: relative;
}

.directory-invite-modal-search:after {
  background-color: #D7D7D7;
  content: "";
  display: block;
  height: 1px;
  margin: $line-height-computed-sm $grid-gutter-width-lg $line-height-computed-sm;
  position: relative;
}

.directory-invite-modal-search-input {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0;
  padding: 4px 8px 4px ($grid-gutter-width + 8px);
  text-align: center;
  transition: 200ms ease-in-out border-color;

  &:focus, &:active {
    border-color: #516584;
  }
}

.directory-invite-modal-search-icon {
  color: #D7D7D7;
  left: ($grid-gutter-width + 8px);
  position: absolute;
  top: ($line-height-computed-sm + 5px);
  z-index: 1;
}

.directory-invite-modal-search-hint {
  color: #AAAAAA;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: ($line-height-computed / 12px);
  text-align: center;
}

/* -------------- */
/* Search results */
/* -------------- */

.directory-invite-modal-result {
  margin: 0;
  max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  padding: 0;
}

.directory-invite-modal-result-loader {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.directory-invite-modal-result-loader-icon {
  margin-right: $grid-gutter-width-xs;
  animation: loader-spin 1.2s linear infinite;
}

.directory-invite-modal-result-message {
  margin: 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ----------- */
/* Member list */
/* ----------- */

.directory-invite-modal-member-list {
  list-style: none;
  margin: 0;
  max-height: (48px * 3);
  overflow-y: scroll;
  padding: 0;
}

.directory-invite-modal-member-list-item {
  align-items: center;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 8px $grid-gutter-width-sm;
  transition: background-color 200ms ease-in-out;
  width: 100%;

  &:hover {
    background-color: $body-bg;
  }
}

.directory-invite-modal-member-list-item-initials {
  background-color: $sidebar-border-color;
  border: 2px solid #ffffff;
  border-radius: 50%;
  flex: 0 0 auto;
  font-family: inherit;
  height: 32px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 32px;
}

.directory-invite-modal-member-list-item-initials-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: (28 / 12);
}

.directory-invite-modal-member-list-item-name {
  flex: 1 1 auto;
  font-weight: 700;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.directory-invite-modal-member-list-item-action {
  align-items: center;
  background-color: $body-bg;
  border: none;
  border-radius: $border-radius;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 0 $grid-gutter-width-xs;
  padding: 2px 8px;
  text-align: center;
  transition: background-color 200ms ease-in-out;

  &:hover, &:focus {
    background-color: #ffffff;
  }
}

.directory-invite-modal-member-list-item-action-icon {
  margin-right: $grid-gutter-width-xs;
}

/* ------------- */
/* Selected list */
/* ------------- */

.directory-invite-modal-selected-list {
  position: relative;
}

.directory-invite-modal-selected-list:before {
  background-color: #D7D7D7;
  content: "";
  display: block;
  height: 1px;
  margin: $line-height-computed-sm $grid-gutter-width-lg $line-height-computed-sm;
  position: relative;
}

.directory-invite-modal-selected-list-header {
  margin: $line-height-computed-xs 0 $line-height-computed-sm 0;
  padding: 0 $grid-gutter-width-sm;
  text-align: center;
}


/* ----------- */
/* Action list */
/* ----------- */

.directory-invite-modal-action-list {
  border-top: solid 1px #D7D7D7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.directory-invite-modal-action-list-item {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  padding: 2px 8px;
  margin: 0;
  text-decoration: none;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):hover, &:not([disabled]):focus {
    background-color: fade_out($brand-color-cyan, 0.25);
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: default;
    background-color: #EAEAEA;
  }
}
