@mixin directory-reference {
  background-color: $sidebar-border-color;
  border-radius: 4px;
  color: $secondary-brand-color;
  display: inline-block;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 2px;
  margin-left: $grid-gutter-width-xs;
  padding: 0 $grid-gutter-width-xs;
  white-space: nowrap;
}
