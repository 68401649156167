.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
}

.markdown-body > *:first-child {
  margin-top: 0 !important;
}

.markdown-body > *:last-child {
  margin-bottom: 0 !important;
}

.markdown-body::before {
  display: table;
  content: "";
}

.markdown-body::after {
  display: table;
  clear: both;
  content: "";
}

.markdown-body a {
  color: $blue-grey;
  text-decoration: underline;

  &:hover, &:focus, &:active {
    color: $body-color;
    outline-width: 0;
  }
}

.markdown-body abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

.markdown-body b,
.markdown-body strong {
  font-weight: 600;
}

.markdown-body small {
  font-size: $font-size * 0.857142857;
}

.markdown-body sub,
.markdown-body sup {
  font-size: $font-size * 0.714285714;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.markdown-body sub {
  bottom: -0.25em;
}

.markdown-body sup {
  top: -0.5em;
}

.markdown-body hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid var(--color-border-muted);
  height: .25em;
  padding: 0;
  margin: $line-height-computed 0;
  background-color: var(--color-border-default);
  border: 0;
}

.markdown-body hr::before {
  display: table;
  content: "";
}

.markdown-body hr::after {
  display: table;
  clear: both;
  content: "";
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin: 0 0 $line-height-computed 0;
  padding: 0;
  font-weight: 600;
}

.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  line-height: $line-height-computed;
}

.markdown-body h1 {
  font-size: $font-size * 2;
}

.markdown-body h2 {
  font-size: $font-size * 1.714285714;
}

.markdown-body h3 {
  font-size: $font-size * 1.428571429;
}

.markdown-body h4 {
  font-size: $font-size * 1.285714286;
}

.markdown-body h5 {
  font-size: $font-size * 1.142857143;
}

.markdown-body h6 {
  font-size: $font-size;
}

.markdown-body ul,
.markdown-body ol {
  margin: 0 0 $line-height-computed 0;
  padding-left: $line-height-computed;
}

.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
  margin: 0;
}

.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}

.markdown-body ul ul ol,
.markdown-body ul ol ol,
.markdown-body ol ul ol,
.markdown-body ol ol ol {
  list-style-type: lower-alpha;
}

.markdown-body ol[type="1"] {
  list-style-type: decimal;
}

.markdown-body ol[type=a] {
  list-style-type: lower-alpha;
}

.markdown-body ol[type=i] {
  list-style-type: lower-roman;
}

.markdown-body div > ol:not([type]) {
  list-style-type: decimal;
}

.markdown-body p,
.markdown-body blockquote {
  margin: 0 0 $line-height-computed 0;
}

.markdown-body blockquote > :first-child {
  margin-top: 0;
}

.markdown-body blockquote > :last-child {
  margin-bottom: 0;
}

.markdown-body sup > a::before {
  content: "[";
}

.markdown-body sup > a::after {
  content: "]";
}
