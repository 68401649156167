.custom-view-btngrp {
  background: #ffffff;
  border-bottom: 2px solid $body-bg;
  display: flex;
  justify-content: end;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
}
.custom-view-btngrp-btn {
  align-items: center;
  background: $sidebar-color;
  border: none;
  border-radius: $border-radius;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  line-height: $line-height;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color, 200ms ease-in-out color;

  &:hover, &:focus {
    background-color: $light-grey;
    color: $body-color;
  }

  & > span {
    margin-right: $grid-gutter-width-xs;
  }
}
.custom-view-list {
  background-color: #ffffff;
  width: 100%;
}
.custom-view-list-empty {
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
  & > span {
    vertical-align: bottom;
  }
}
.custom-view-list-item {
  display: flex;
  align-items: center;
  gap: $grid-gutter-width-sm;
  grid-template-columns: 1fr auto auto auto auto;
  padding: $line-height-computed-sm $grid-gutter-width-sm;

  &:not(:last-child) {
    border-bottom: 2px solid $body-bg;
  }
}
.custom-view-list-item-name {
  min-width: 150px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-view-list-item-badge {
  color: $blue-grey;
  flex-basis: 32px;
  font-size: $font-size * 0.857142857;
  overflow: hidden;
}
.custom-view-list-item-badge-icon {
  font-size: $font-size * 0.857142857;
}
.custom-view-list-item-action {
  align-items: center;
  background-color: $btn-primary;
  border: none;
  border-radius: $border-radius;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: center;
  margin: 0;
  padding: $line-height-computed-xs $line-height-computed-sm;
  transition: 200ms ease-in-out background-color, 200ms ease-in-out color;

  &:hover, &:focus {
    background-color: lighten($btn-primary, 38%);
    color: darken($btn-primary, 20%);
  }

  &.mod-alert {
    background-color: lighten($color-alert, 38%);
    color: darken($color-alert, 20%);

    &:hover, &:focus {
      background-color: desaturate(darken($color-alert, 10%), 25%);
      color: #ffffff;
    }
  }
}
.custom-view-list-item-action-icon {
  font-size: $font-size;
  margin-right: $grid-gutter-width-xs;
}

.custom-view-form-modal {
  width: 80vw !important;
  position: relative;
  max-height: 90vh !important;
  overflow: hidden;

  .custom-view-form-modal-close {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    font-family: inherit;
    font-size: 12px;
    font-weight: 400;
    line-height: (24 / 12);
    margin: 0;
    padding: $line-height-computed-xs $grid-gutter-width-xs;
    position: absolute;
    right: $grid-gutter-width-xs;
    top: 0;
    transition: 200ms ease-in-out background-color;
    z-index: 1;

    &:hover, &:focus, &:active {
      background-color: #ffffff;

      .custom-view-form-modal-close-icon,
      .custom-view-form-modal-close-icon {
        color: $body-color;
      }
    }

    .custom-view-form-modal-close-icon {
      color: $blue-grey;
      display: block;
      font-size: 32px;
      transition: 200ms ease-in-out color;
    }
  }
}
.custom-view-modal-form-body {
  margin: 0;
  overflow-y: auto;
  max-height: calc(90vh - (5 * #{$line-height-computed})) /* Retire la taille du header et du footer de la modal */;
}
.custom-view-modal-form-member-row {
  & > td {
    padding: $line-height-computed-xs $grid-gutter-width-xs;
  }
}
.custom-view-modal-form-member-row-avatar {
  width: 48px;
}
.custom-view-modal-form-member-row-avatar-icon {
  display: block;
  background-color: $sidebar-border-color;
  border: 2px solid #ffffff;
  border-radius: 50%;
  flex: 0 0 auto;
  font-family: inherit;
  height: 32px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 32px;

  & > span {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: (28/12);
  }
}
.custom-view-modal-form-member-row-email {
  color: $sidebar-color;
  font-style: italic;
}
.custom-view-modal-form-member-row-action {}
.custom-view-modal-form-member-row-action-btn {
  align-items: center;
  background-color: $body-bg;
  border: none;
  border-radius: $border-radius;
  color: $body-color;
  cursor: pointer;
  display: block;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 0 $grid-gutter-width-xs;
  padding: 2px 8px;
  text-align: center;
  width: 112px;

  &:hover, &:focus {
    background-color: #ffffff;
  }

  &.mod-allowed {
    background-color: desaturate(lighten($color-success, 48%), 33%);
    color: darken($color-success, 20%);

    & .custom-view-modal-form-member-row-action-btn-icon {
      color: $color-success;
    }

    & > .custom-view-modal-form-member-row-action-btn-action {
      display: none;
    }

    &:hover, &:focus {
      background-color: lighten($color-alert, 38%);
      color: darken($color-alert, 20%);

      & > .custom-view-modal-form-member-row-action-btn-state {
        display: none;
      }

      & > .custom-view-modal-form-member-row-action-btn-action {
        display: flex;
      }

      & .custom-view-modal-form-member-row-action-btn-icon {
        color: $color-alert;
      }
    }
  }

  &.mod-denied {
    background-color: lighten($color-alert, 38%);
    color: darken($color-alert, 20%);

    & .custom-view-modal-form-member-row-action-btn-icon {
      color: $color-alert;
    }

    & > .custom-view-modal-form-member-row-action-btn-action {
      display: none;
    }

    &:hover, &:focus {
      background-color: desaturate(lighten($color-success, 48%), 33%);
      color: darken($color-success, 20%);

      & > .custom-view-modal-form-member-row-action-btn-state {
        display: none;
      }

      & > .custom-view-modal-form-member-row-action-btn-action {
        display: flex;
      }

      & .custom-view-modal-form-member-row-action-btn-icon {
        color: $color-success;
      }
    }
  }
}

.custom-view-modal-form-member-row-action-btn-state,
.custom-view-modal-form-member-row-action-btn-action {
  align-items: center;
  display: flex;
  justify-content: start;
}

.custom-view-modal-form-member-row-action-btn-icon {
  margin-right: $grid-gutter-width-xs;
}

.custom-view-modal-form-milestone {
  align-items: center;
  background-color: $body-bg;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm $line-height-computed-sm $grid-gutter-width-sm;
  text-decoration: none;
}
.custom-view-modal-form-milestone-checkbox {
  background-color: transparent;
  border: none;
  color: $dark-grey;
  cursor: pointer;
  margin: -2px $grid-gutter-width-xs 0 0;
  padding: 0;

  &.mod-checked {
    color: $body-color;
  }
}
.custom-view-modal-form-milestone-title {
  flex-grow: 1;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-view-modal-form-task {
  align-items: center;
  border-bottom: 2px solid $body-bg;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm $line-height-computed-sm $grid-gutter-width-sm;
  text-decoration: none;
}
.custom-view-modal-form-task-checkbox {
  background-color: transparent;
  border: none;
  color: $dark-grey;
  cursor: pointer;
  margin: -2px $grid-gutter-width-xs 0 0;
  padding: 0;

    &.mod-checked {
      color: $color-success;
    }
}
.custom-view-modal-form-task-title {
  flex-grow: 1;
  margin-left: $grid-gutter-width;
  overflow: hidden;
  padding: $line-height-computed-xs 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-view-select-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: $grid-gutter-width-sm;

  & > label {
    font-weight: 700;
  }

  .ember-basic-dropdown-content-wormhole-origin {
    display: none;
  }

  .custom-view-selector {
    min-width: 100px;
    flex: 0 1 50%;
  }
}
