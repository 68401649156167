.btn {
  background-color: $body-color;
  border: none;
  border-radius: $border-radius;
  color: $body-bg;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height-computed;
  margin: 0;
  padding: ($line-height-computed / 4) $grid-gutter-width-sm;
  text-align: center;

  &:hover, &:focus {
    background-color: $body-bg;
    color: $body-color;
  }

  &.mod-invert {
    background-color: $body-bg;
    color: $body-color;
  }
  &.mod-invert:hover, &.mod-invert:focus {
    background-color: $body-color;
    color: $body-bg;
  }

  &.mod-white {
    background-color: #FFFFFF;
    color: $blue-grey;
    text-decoration: none;

    &:hover, &:focus {
      background-color: $blue-grey;
      color: #FFFFFF;
    }
  }

  &.mod-alert {
    background-color: $color-alert;
    color: #ffffff;
  }
  &.mod-alert:hover, &.mod-alert:focus {
    background-color: desaturate(darken($color-alert, 10%), 25%);
    color: #ffffff;
  }

  &:disabled, &.mod-disabled, &.mod-invert:disabled, &.mod-alert:disabled {
    background-color: $light-grey;
    color: $dark-grey;
    cursor: not-allowed;
  }

  &.mod-link {
    background-color: transparent;
    border: none;
    color: $body-bg;
    cursor: pointer;
    display: inline;
    font-size: $font-size;
    line-height: $line-height-computed;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;

    &:hover, &:focus {
      background-color: transparent;
      color: $body-color;
    }

    &.mod-contrast {
      background-color: transparent;
      color: $body-color;

      &:hover, &:focus {
        background-color: transparent;
        color: $dark-grey;
      }
    }
  }

  &.mod-loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $grid-gutter-width-sm;

    &.is-loading {
      cursor: wait;
    }
  }

  &.mod-primary {
    background-color: $btn-primary;
    color: #ffffff;
  }
}

.btn-icon {
  animation: loader-spin 1.2s linear infinite;

  .btn.mod-loader:not(.is-loading) & {
    display: none;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
