@mixin media-breakpoint-down($size) {
  @if $size == md {
    @media (max-width: $screen-md) { @content; }
  } @else if $size == sm {
    @media (max-width: $screen-sm) { @content; }
  } @else if $size == xs {
    @media (max-width: $screen-xs) { @content; }
  } @else if $size == xss {
    @media (max-width: $screen-xss) { @content; }
  }

}
