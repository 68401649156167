.task-ical-modal {
  min-height: $line-height-computed-lg * 1.5;
}

.task-ical-modal-choices {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin: $line-height-computed 0;
  padding: 0 $grid-gutter-width-sm;
}

.task-ical-modal-choices-item {
  align-items: center;
  background-color: #ffffff;
  border: solid 1px $brand-color-cyan;
  border-radius: 4px;
  color: $brand-color-cyan;
  cursor: pointer;
  display: block;
  flex: 0 0 120px;
  font-family: inherit;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out border-color,
              200ms ease-in-out color;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: lighten($brand-color-cyan, 5%);
    border-color: lighten($brand-color-cyan, 5%);
    color: #ffffff;
  }

  &[disabled] {
    border-color: #d7d7d7;
    color: #AAAAAA;
    cursor: not-allowed;
  }
}

.task-ical-modal-choices-item-icon {
  display: block;
  font-size: 48px;
}

.task-ical-modal-choices-item-text {
  display: block;
  font-size: inherit;
  font-weight: 400;
  line-height: inherit;
}
