.task-modal-chatbox {
  background-color: #f3f4f6;
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  max-height: 80vh;
  min-width: 300px;
  padding: $line-height-computed $grid-gutter-width;
}

.task-modal-chatbox-title {
  flex: 0 0 $line-height-computed;
  font-size: 16px;
  font-weight: 400;
  line-height: ($line-height-computed / 16px);
  margin: 0 0 $line-height-computed;
  padding: 0;
  position: relative;
}

.task-modal-chatbox-title:after {
  background-color: $blue-grey;
  bottom: -$line-height-computed;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
  display: block;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}

/* ------------ */
/* Message list */
/* ------------ */

.task-modal-chatbox-list {
  display: flex;
  flex-direction: column-reverse;
  flex: 1 1 auto;
  list-style: none;
  margin: 0;
  overflow-y: scroll;
  padding: 0 $grid-gutter-width-xs 0 0;
  scroll-snap-type: y mandatory;

  &.mod-no-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.task-modal-chatbox-list-item {
  margin: 0;
  padding: 0;
  scroll-snap-align: end;

  &:not(:last-child) {
    margin-top: $line-height-computed-sm;
  }

  &:last-child:before {
    // Permet de forcer l'affichage de la marge au-dessus
    content: ' ';
    display: block;
    height: $line-height-computed-sm;
    width: 100%;
  }
}

.task-modal-chatbox-list-item-meta {
  align-items: center;
  display: flex;
  overflow: hidden;
  flex-direction: row;
}

.task-modal-chatbox-list-item-meta-author {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.task-modal-chatbox-list-item-meta-author-initials {
  background-color: $sidebar-border-color;
  border: 1px solid #ffffff;
  border-radius: 50%;
  flex: 0 0 auto;
  font-family: inherit;
  height: 24px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 24px;
}

.task-modal-chatbox-list-item-meta-author-initials-text {
  border-radius: 50%;
  bottom: 0;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  line-height: (22 / 12);
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  z-index: 1;
}

.task-modal-chatbox-list-item-meta-author-name {
  color: $blue-grey;
  flex: 0 1 auto;
  font-size: 12px;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-modal-chatbox-list-item-meta-date {
  color: $blue-grey;
  flex: 0 1 auto;
  font-size: 12px;
  line-height: ($line-height-computed / 12px);
  margin: 0 0 0 $grid-gutter-width-sm;
  overflow: hidden;
  padding: 0;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;

  .task-modal-chatbox-list-item:hover &.mod-short {
    display: none;
  }

  .task-modal-chatbox-list-item:not(:hover) &.mod-detailed {
    display: none;
  }
}

.task-modal-chatbox-list-item-message {
  background-color: #ffffff;
  border: solid 1px $light-grey-2;
  border-radius: 8px;
  color: $body-color;
  margin: $line-height-computed-xs 0 0 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  position: relative;

  &.is-deleted {
    background-color: rgba(255, 255, 255, 0.4);
    color: $dark-grey;
    font-size: 12px;
    font-style: italic;
    line-height: $line-height-computed / 12px;
  }

  .task-modal-chatbox-list-item:nth-child(2n) &:not(.is-deleted) {
    background-color: #F3FAFF;
  }
}

.task-modal-chatbox-list-item-message-delete {
  background-color: rgba(255, 255, 255, 0.75);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  color: $blue-grey;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: ($grid-gutter-width-xs / 2) ($grid-gutter-width-xs / 2);
  position: absolute;
  right: $grid-gutter-width-xs / 2;
  top: $line-height-computed-xs / 2;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;
  z-index: 1;

  &:hover, &:focus {
    background-color: $sidebar-hover-color;
    color: $body-color;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  .task-modal-chatbox-list-item:not(:hover) & {
    display: none;
  }
}

.task-modal-chatbox-list-item-message-delete-icon {
  display: block;

  .task-modal-chatbox-list-item-message-delete:not(.is-loading) &.mod-loader {
    display: none;
  }
  .task-modal-chatbox-list-item-message-delete.is-loading &:not(.mod-loader) {
    display: none;
  }
  .task-modal-chatbox-list-item-message-delete.is-loading &.mod-loader {
    animation: loader-spin 1.2s linear infinite;
  }
}

.task-modal-chatbox-list-empty {
  margin: auto 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
}

.task-modal-chatbox-list-empty-icon {
  color: $brand-color-cyan;
  display: block;
  font-size: 48px;
  margin: 0 0 $line-height-computed 0;
}

/* ------------ */
/* Message form */
/* ------------ */

.task-modal-chatbox-form {
  align-items: center;
  background-color: #ffffff;
  border: solid 1px $blue-grey;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: $line-height-computed 0 0 0;
  padding: 0;
}

.task-modal-chatbox-form-input {
  background: transparent;
  border: none;
  border-radius: 8px;
  color: $body-color;
  flex: 1 1 auto;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: (24 / 14);
  margin: 0;
  min-width: 0;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.task-modal-chatbox-form-button {
  background-color: $brand-color-cyan;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: (24 / 12);
  margin: 0 $grid-gutter-width-sm;
  padding: 0 $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):not(.is-loading):hover,
  &:not([disabled]):not(.is-loading):focus {
    background-color: lighten($brand-color-cyan, 5%);
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: not-allowed;
    background-color: #EAEAEA;
  }

  &.is-loading {
    color: #AAAAAA;
    cursor: wait;
    background-color: #EAEAEA;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
