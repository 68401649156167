$emberTooltipBgColor: rgba(64, 83, 108, 0.9);

.ember-tooltip {
  background-color: $emberTooltipBgColor;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(85, 85, 85, 0.8);
  padding: 0 8px;

  &[x-placement^="bottom"] > .ember-tooltip-arrow {
    border-bottom-color: $emberTooltipBgColor;
  }
  &[x-placement^="left"] > .ember-tooltip-arrow {
    border-left-color: $emberTooltipBgColor;
  }
  &[x-placement^="right"] > .ember-tooltip-arrow {
    border-right-color: $emberTooltipBgColor;
  }
  &[x-placement^="top"] > .ember-tooltip-arrow {
    border-top-color: $emberTooltipBgColor;
  }

  & > .ember-tooltip-inner {
    color: #FFFFFF;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    line-height: (24 / 13);
    text-shadow: none;
  }
}
