.drag-and-drop-scrollable-container {
  position: relative;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;

  &.mod-active {
    cursor: pointer;
  }
}
