.project-timeline {
  background-color: #FFFFFF;
  margin: $grid-gutter-width;
  min-width: 770px;
}

/* ------ */
/* Navbar */
/* ------ */

.project-timeline-nav {
  align-items: center;
  border-bottom: 1px solid $sidebar-border-color;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  min-height: $line-height-computed-md + 1px; // +1px border
  padding: $line-height-computed-sm $grid-gutter-width-sm;
}

.project-timeline-nav-center {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 auto;
}

.project-timeline-nav-item:not(.mod-dropdown) {
  background-color: #ffffff;
  border: solid 1px $brand-color-cyan;
  border-radius: 4px;
  color: $brand-color-cyan;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: (22 / 12);
  margin: 0 0 0 $grid-gutter-width-sm;
  padding: 0 $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color,
  200ms ease-in-out border-color,
  200ms ease-in-out color;

  &:not([disabled]):not(.is-loading):hover {
    background-color: lighten($brand-color-cyan, 5%);
    border-color: lighten($brand-color-cyan, 5%);
    color: #ffffff;
  }

  &.is-loading {
    cursor: wait;
  }

  &.mod-icon {
    padding: 0 ($grid-gutter-width-xs / 2);
  }

  &[disabled] {
    border-color: #d7d7d7;
    color: #CCCCCC;
    cursor: not-allowed;
  }
}

.project-timeline-nav-item-icon {
  display: block;
  height: 22px;
  line-height: (22 / 24);
}

.project-timeline-nav-item.mod-dropdown {
  .ember-power-select-trigger {
    background-color: #ffffff;
    border: solid 1px $brand-color-cyan;
    border-radius: 4px;
    color: $brand-color-cyan;
    cursor: pointer;
    font-family: inherit;
    font-size: 12px;
    font-weight: 400;
    line-height: (22 / 12);
    margin: 0 0 0 $grid-gutter-width-sm;
    padding: 0 ($grid-gutter-width-sm + $grid-gutter-width-xs) 0 $grid-gutter-width-xs;
    transition: 200ms ease-in-out background-color,
                200ms ease-in-out border-color,
                200ms ease-in-out color;

    &:hover {
      background-color: lighten($brand-color-cyan, 5%);
      border-color: lighten($brand-color-cyan, 5%);
      color: #ffffff;
    }
  }

  .ember-power-select-selected-item {
    margin: 0;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:before {
      content: "Vue :";
      display: inline;
      position: relative;
    }
  }

  .ember-power-select-status-icon {
    border-top-color: $brand-color-cyan;
    transition: 200ms ease-in-out border-color;
  }

  .ember-power-select-trigger:hover .ember-power-select-status-icon {
    border-top-color: #ffffff;
  }
}

.project-timeline-nav-dropdown {
  border-color: #d7d7d7;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.25);
  min-width: 125px;

  &.ember-basic-dropdown-content--below {
    border-bottom-color: #d7d7d7;
  }
  &.ember-basic-dropdown-content--above {
    border-top-color: #d7d7d7;
  }

  .ember-power-select-options {
  }

  .ember-power-select-option {
    color: $body-color;
    padding: 3px $grid-gutter-width-sm;
    transition: 200ms ease-in-out background-color,
                200ms ease-in-out color;

    &[aria-current=true] {
      background-color: $body-bg;
      color: $body-color;
    }

    &[aria-selected=true] {
      background-color: $brand-color-cyan;
      color: #FFFFFF;
      font-weight: 700;

      &[aria-current=true] {
        background-color: lighten($brand-color-cyan, 5%);
      }
    }
  }
}

/* ---- */
/* Body */
/* ---- */

.project-timeline-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

/* -------------- */
/* Sidebar column */
/* -------------- */

.project-timeline-sidebar {
  border-right: 1px solid $sidebar-border-color;
  flex: 0 1 45%;
  min-width: 300px;
  overflow: hidden;
}

.project-timeline-sidebar-row {
  min-height: $line-height-computed-md;
  overflow: hidden;
  padding: $grid-gutter-width-sm $grid-gutter-width-sm;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.mod-project {
    font-size: 20px;
    font-weight: 500;
    line-height: ($line-height-computed / 20px);
  }

  &.mod-milestone {
    background-color: $sidebar-background-color;
    font-weight: 500;
    padding-left: $grid-gutter-width;
  }

  &.mod-task {
    padding-left: $grid-gutter-width + $grid-gutter-width-sm;
  }
}

.project-timeline-sidebar-row-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: $blue-grey;
    text-decoration: underline;
  }
}

/* ----------- */
/* User column */
/* ----------- */

.project-timeline-user {
  border-right: 1px solid $sidebar-border-color;
  flex: 0 0 auto;
}

.project-timeline-user-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: $line-height-computed-md;
  min-width: $grid-gutter-width-md;
  padding: $grid-gutter-width-sm $grid-gutter-width-sm;
  text-align: center;

  &.mod-milestone {
    background-color: $sidebar-background-color;
  }

  &.mod-task {
    padding-bottom: $line-height-computed-xs;
    padding-top: $line-height-computed-xs;
  }
}

/* --------------- */
/* Progress column */
/* --------------- */

.project-timeline-progress {
  border-right: 1px solid $sidebar-border-color;
  flex: 0 0 auto;
}

.project-timeline-progress-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: $line-height-computed-md;
  min-width: $grid-gutter-width-md;
  padding: $grid-gutter-width-sm ($grid-gutter-width-xs / 2);
  text-align: center;

  &.mod-milestone {
    background-color: $sidebar-background-color;
  }

  &.mod-task {
    padding-bottom: $line-height-computed-xs;
    padding-top: $line-height-computed-xs;
  }
}

.project-timeline-progress-checkbox {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: $sidebar-hover-color;
  }

  &.is-loading {
    background-color: $sidebar-hover-color;
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.is-static {
    cursor: default;
  }
}

.project-timeline-progress-checkbox-icon {
  background-color: #ffffff;
  border: 2px solid $light-grey;
  border-radius: 50%;
  color: $light-grey;
  display: block;
  font-size: 20px;
  height: 24px;
  line-height: (24/22);
  width: 24px;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out border-color,
              200ms ease-in-out color;

  .project-timeline-progress-checkbox:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):hover &,
  .project-timeline-progress-checkbox:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):focus & {
    background-color: $brand-color-cyan;
    border-color: $brand-color-cyan;
    color: #ffffff;
  }

  .project-timeline-progress-checkbox.is-checked & {
    background-color: $color-success;
    border-color: $color-success;
    color: #ffffff;
  }
}

/* -------- */
/* Calendar */
/* -------- */

.project-timeline-calendar {
  display: grid;
  grid-template-columns: repeat(var(--columns, 1), minmax($grid-gutter-width-lg, 1fr));
  grid-template-rows: repeat(var(--rows, 2), $line-height-computed-md);
  grid-auto-rows: auto;
  min-width: 400px;
  overflow: auto;
  scroll-snap-type: x proximity;
  width: 100%;
}

.project-timeline-calendar-header {
  background-color: transparent;
  border-right: 1px solid $sidebar-border-color;
  grid-column: calc(var(--column, 1) + 1); // +1 car l'index commence à 1 et non pas 0
  grid-row-start: 1;
  grid-row-end: calc(var(--rows, 1) + 1); // +1 car l'index commence à 1 et non pas 0
  margin: 0;
  padding: 0;
  position: relative;
  scroll-snap-align: start;

  &.is-odd {
    background-color: $sidebar-background-color;
  }

  &.is-today {
    background-color: #FFF5E5;
  }
}

.project-timeline-calendar-header-date,
.project-timeline-calendar-header-date-sticky {
  border-bottom: 1px solid $sidebar-border-color;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width-xs ($line-height-computed-sm - 1px);
  text-align: center;
  user-select: none;

  &.is-today {
    font-weight: 700;
    text-decoration: underline;
  }
}

.project-timeline-calendar-header-date-sticky {
  background-color: #FFFFFF;
  border-bottom: 1px solid $sidebar-border-color;
  border-right: 1px solid $sidebar-border-color;
  display: none;
  left: 0;
  position: absolute;
  right: -1px;
  top: 0;
  z-index:2;

  .project-timeline-calendar-header.is-odd & {
    background-color: $sidebar-background-color;
  }

  .project-timeline-calendar-header.is-today & {
    background-color: #FFF5E5;
  }

  .project-timeline-calendar-header-date.is-sticky & {
    display: block;
  }

  .project-timeline-calendar-header-date.is-today & {
    font-weight: 700;
    text-decoration: underline;
  }
}

.project-timeline-calendar-item {
  background-color: $brand-color-cyan;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
  grid-column: calc(var(--start, 1) + 1) / span var(--duration, 1); // +1 car l'index commence à 1 et non pas 0
  grid-row: calc(var(--row, 1) + 2); // +1 car l'index commence à 1 et non pas 0, +1 pour la ligne des dates
  margin: $line-height-computed-xs $grid-gutter-width-xs;
  overflow: hidden;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  position: relative;
  text-align: center;
  white-space: nowrap;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out box-shadow;
  z-index: 1; // permet de passer par-dessus .project-timeline-calendar-column

  &:hover, &:focus {
    background-color: mix($brand-color-cyan, $light-grey, 65%);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  &.is-completed {
    background-color: $color-success;

    &:hover, &:focus {
      background-color: mix($color-success, $light-grey, 65%);
    }
  }

  &.is-hidden {
    display: none;
  }
}

.project-timeline-calendar-item-title {
  opacity: 0;
  transition: 200ms ease-in-out opacity;

  .project-timeline-calendar-item:hover &,
  .project-timeline-calendar-item:focus & {
    opacity: 1;
  }
}
