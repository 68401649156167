.workspace-selector {
  display: block;
}

.workspace-selector-item {
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  height: 52px;
  line-height: (20 / 16);
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;

  &[disabled] {
    color: #AAAAAA;
    cursor: default;
  }
}

.workspace-selector-item-content {
  align-items: center;
  background-color: transparent;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 10px $grid-gutter-width;
  transition: 200ms ease-in-out background-color;

  // Effet hover uniquement si élément pas désactivé
  .workspace-selector-item:not([disabled]):hover &,
  .workspace-selector-item:not([disabled]):active & {
    background-color: rgba(81, 101, 132, 0.1);
  }
}

.workspace-selector-item-logo {
  margin-right: $grid-gutter-width-sm;
  max-height: 32px;
}
