.directory-list {
  display: grid;
  grid-gap: 16px 32px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: $line-height-computed-sm 0;
  padding: 0 $grid-gutter-width;

  &.mod-space-bottom {
    margin-bottom: $line-height-computed-md;
  }
}

.directory-list-item {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #F2F2F2;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  color: #555555;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: (48 / 14);
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-decoration: none;
  transition:
    200ms ease-in-out background-color,
    200ms ease-in-out border-color;

  &:hover, &:focus {
    background-color: rgba(81, 101, 132, 0.1);
    border-color: rgba(81, 101, 132, 0.05);
  }
}

.directory-list-item-icon {
  color: $brand-color-cyan;
  flex: 0 0 24px;
  margin: 0 $grid-gutter-width-xs 0 $grid-gutter-width-sm;

  &.mod-blue {
    color: $brand-color-blue;
  }
  &.mod-green {
    color: $brand-color-green;
  }
  &.mod-orange {
    color: $brand-color-orange;
  }
}

.directory-list-item-title {
  color: inherit;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.directory-list-item-button {
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  flex: 0 0 48px;
  margin: 0;
  max-height: 48px;
  padding: 8px;

  &.mod-on-hover {
    display: none;
  }
  .directory-list-item:focus &.mod-on-hover,
  .directory-list-item:hover &.mod-on-hover,
  .directory-list-item:active &.mod-on-hover {
    display: inline;
  }

  &[disabled] {
    color: #AAAAAA;
    cursor: not-allowed;
    background-color: #EAEAEA;
  }
}

.directory-list-item-button-icon {
  background-color: transparent;
  border-radius: 50%;
  padding: 4px;
  transition: 200ms ease-in-out background-color;

  .directory-list-item-button:not([disabled]):hover &,
  .directory-list-item-button:not([disabled]):focus & {
    background-color: rgba(255, 255, 255, 0.75);
  }

  &.mod-yellow {
    color: $brand-color-yellow;
  }
}
