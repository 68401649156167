.task-list-item-view {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-width: 0;
}

.task-list-item-view-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

  .task-list-item-view-content-checkbox {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    flex: 0 0 auto;
    margin: 0 $grid-gutter-width-xs 0 0;
    overflow: hidden;
    padding: $line-height-computed-xs $grid-gutter-width-xs;
    transition: 200ms ease-in-out background-color;

    &:not([disabled]):not(.is-loading):not(.is-static):hover,
    &:not([disabled]):not(.is-loading):not(.is-static):focus {
      background-color: $sidebar-hover-color;
    }

    &.is-loading {
      cursor: wait;
    }

    &[disabled] {
      cursor: not-allowed;
    }

    &.is-static {
      cursor: default;
    }
  }

    .task-list-item-view-content-checkbox-icon {
      background-color: #ffffff;
      border: 2px solid $light-grey;
      border-radius: 50%;
      color: $light-grey;
      display: block;
      font-size: 20px;
      height: 24px;
      line-height: (24/22);
      width: 24px;
      transition: 200ms ease-in-out background-color,
      200ms ease-in-out border-color,
      200ms ease-in-out color;

      .task-list-item-view-content-checkbox:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):hover &,
      .task-list-item-view-content-checkbox:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):focus & {
        background-color: $brand-color-cyan;
        border-color: $brand-color-cyan;
        color: #ffffff;
      }

      .task-list-item-view-content-checkbox.is-checked & {
        background-color: $color-success;
        border-color: $color-success;
        color: #ffffff;
      }
    }

  .task-list-item-view-content-hasdescription {
    margin-left: $grid-gutter-width-sm;
  }

  .task-list-item-view-content-text {
    background: transparent;
    border: none;
    border-bottom: 1px dashed transparent;
    color: $body-color;
    cursor: text;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: ($line-height-computed - 1px) / $font-size;
    margin-left: $grid-gutter-width-sm;
    min-height: $line-height-computed + ($line-height-computed-xs * 2);
    overflow: hidden;
    padding: $line-height-computed-xs 0;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 150px;

    &.is-editable:hover {
      border-color: $body-color;
      cursor: text;
    }

    &.is-readonly {
      cursor: pointer;
    }

    &.is-empty {
      color: $blue-grey;
      font-style: italic;
    }
  }

.task-list-item-view-badges {
  align-self: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: $line-height-computed / 12px;
  list-style: none;
  margin: 0 0 0 ($grid-gutter-width / 2);
  padding: $line-height-computed-xs 0;

  &-item {
    align-items: center;
    color: $sidebar-color;
    display: flex;
    flex-basis: 30px;
    line-height: $line-height-computed;
    max-width: 30px;
    min-width: 30px;

    &-icon {
      font-size: $font-size;
    }
  }
}

.task-list-item-view-information {
  align-self: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: $line-height-computed / 12px;
  list-style: none;
  margin: 0 0 0 ($grid-gutter-width / 2);
  padding: $line-height-computed-xs 0;


  @include media-breakpoint-down(xs) {
    display: none;
  }
}

  .task-list-item-view-information-field,
  .task-list-item-view-information-url {
    align-self: center;
    flex-basis: 100px; // hack pour garder une taille à 100px
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.mod-empty {
      color: rgba(0, 0, 0, 0.15);
    }

    &:not(:first-child) {
      margin-left: $grid-gutter-width-sm;
    }
  }

  .task-list-item-view-information-field {
    text-align: center;

    &.mod-list {
      border-radius: $border-radius / 2;
      color: #FFFFFF;
    }

    &.mod-wide {
      // hack pour garder une taille à 200px
      flex-basis: 200px;
      max-width: 200px;
      min-width: 200px;
    }

    &.mod-small {
      // hack pour garder une taille à 30px
      flex-basis: 30px;
      max-width: 30px;
      min-width: 30px;
    }

    & a {
      color: $body-color;
      display: inline-block;
      &:hover{
        color: darken($body-color, 10);
      }
    }
  }

  .task-list-item-view-information-url {
    max-height: 24px; // hack car overflow: hidden augmente la line-heiht

    & > a {
      color: $body-color;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      &:hover{
        color: darken($body-color, 10);
      }
    }
  }



  .task-list-item-view-information-assignee {
    flex-basis: $grid-gutter-width;
    max-width: $grid-gutter-width;
    min-width: $grid-gutter-width;
    overflow: visible;

    &-avatar {
      border-radius: 50%;
      height: $line-height-computed;
      overflow: hidden;
      width: $grid-gutter-width;
    }
  }


